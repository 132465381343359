@charset "UTF-8";

.App {
    font-family: ModamWeb;
}

h1 {
    text-align: center;
}

form {
    width: 100%;
    margin: 0 auto;
}

label,
input {
    display: block;
    width: 100%;
}

label {
    margin-bottom: 5px;

}

input {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #777;
}

input.error {
    border-color: red;
}

.input-feedback {
    color: rgb(235, 54, 54);
    margin-top: -15px;
    font-size: 14px;
    margin-bottom: 20px;
}

button {
    padding: 10px 15px;
    background-color: rgb(70, 153, 179);
    color: white;
    border: 1px solid rgb(70, 153, 179);
    background-color: 250ms;
}

button:hover {
    cursor: pointer;
    background-color: white;
    color: rgb(70, 153, 179);
}


/*
    Project: Chikery HTML Template
    Version: 1.0.1
    Date Created: 13/06/2019
    Date Updated: 22/07/2019
    Developed by: diaryforlife
*/
/*
    TABLE OF CONTENT
        1. RESET AND TYPHOGRAPHY
            1.1 reset
            1.2 typhography
        2. ELEMENTS
            2.1 grid
            2.2 list
            2.3 button
            2.4 form
            2.5 post
            2.6 pagination
            2.7 breadcrumb
            2.8 block
            2.9 widget
            2.10 slider
            2.11 masonry
            2.12 product
            2.13 banner
            2.14 filter
            2.15 table
            2.16 loader
            2.17 modal
        3. COMPONENTS
             3.1 Cart
             3.2 forms
             3.3 search
             3.4 section
             3.5 pages
        4. MODULES
            4.1 header
            4.2 footer
        5. HELPERS
        6. CUSTOM
*/
.masonry-wrapper .grid-item .grid-item__inner, .setting .grid-item .grid-item__inner {
    position: relative;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper, .setting.ac_masonry-grid .grid-item__content-wrapper {
    position: absolute;
}

.masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper, .setting .grid-item .grid-item__inner .grid-item__content-wrapper {
    overflow: hidden;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper, .setting.ac_masonry-grid .grid-item__content-wrapper {
    margin: 0 !important;
}

.masonry-wrapper.ac_masonry-grid .grid-item__inner {
    padding-top: 100%;
}

.masonry-wrapper.ac_masonry-grid .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid .grid-item.wide .grid-item__inner {
    padding-top: 50%;
}

.masonry-wrapper.ac_masonry-grid .grid-item.high .grid-item__inner {
    padding-top: 200%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item__inner {
    padding-top: 75%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.wide .grid-item__inner {
    padding-top: 37.5%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.high .grid-item__inner {
    padding-top: 150%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item__inner {
    padding-top: 56.25%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.wide .grid-item__inner {
    padding-top: 28.125%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.high .grid-item__inner {
    padding-top: 112.5%;
}

.masonry-wrapper[data-col-xs="1"] .grid-item,
.masonry-wrapper[data-col-xs="1"] .grid-sizer {
    width: 100%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item,
.masonry-wrapper[data-col-xs="2"] .grid-sizer {
    width: 50%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item.wide, .masonry-wrapper[data-col-xs="2"] .grid-item.large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item,
.masonry-wrapper[data-col-xs="3"] .grid-sizer {
    width: 33.33333%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item.wide, .masonry-wrapper[data-col-xs="3"] .grid-item.large {
    width: 66.66667%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item,
.masonry-wrapper[data-col-xs="4"] .grid-sizer {
    width: 25%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item.wide, .masonry-wrapper[data-col-xs="4"] .grid-item.large {
    width: 50%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item,
.masonry-wrapper[data-col-xs="5"] .grid-sizer {
    width: 20%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item.wide, .masonry-wrapper[data-col-xs="5"] .grid-item.large {
    width: 40%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item,
.masonry-wrapper[data-col-xs="6"] .grid-sizer {
    width: 16.66667%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item.wide, .masonry-wrapper[data-col-xs="6"] .grid-item.large {
    width: 33.33333%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item,
.masonry-wrapper[data-col-xs="7"] .grid-sizer {
    width: 14.28571%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item.wide, .masonry-wrapper[data-col-xs="7"] .grid-item.large {
    width: 28.57143%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item,
.masonry-wrapper[data-col-xs="8"] .grid-sizer {
    width: 12.5%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item.wide, .masonry-wrapper[data-col-xs="8"] .grid-item.large {
    width: 25%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item,
.masonry-wrapper[data-col-xs="9"] .grid-sizer {
    width: 11.11111%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item.wide, .masonry-wrapper[data-col-xs="9"] .grid-item.large {
    width: 22.22222%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item.extra-large {
    width: 100%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item,
.masonry-wrapper[data-col-xs="10"] .grid-sizer {
    width: 10%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item.wide, .masonry-wrapper[data-col-xs="10"] .grid-item.large {
    width: 20%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item.extra-large {
    width: 100%;
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="1"] .grid-item,
    .masonry-wrapper[data-col-sm="1"] .grid-sizer {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="2"] .grid-item,
    .masonry-wrapper[data-col-sm="2"] .grid-sizer {
        width: 50%;
    }

    .masonry-wrapper[data-col-sm="2"] .grid-item.wide, .masonry-wrapper[data-col-sm="2"] .grid-item.large {
        width: 100%;
    }

    .masonry-wrapper[data-col-sm="2"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="3"] .grid-item,
    .masonry-wrapper[data-col-sm="3"] .grid-sizer {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-sm="3"] .grid-item.wide, .masonry-wrapper[data-col-sm="3"] .grid-item.large {
        width: 66.66667%;
    }

    .masonry-wrapper[data-col-sm="3"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="4"] .grid-item,
    .masonry-wrapper[data-col-sm="4"] .grid-sizer {
        width: 25%;
    }

    .masonry-wrapper[data-col-sm="4"] .grid-item.wide, .masonry-wrapper[data-col-sm="4"] .grid-item.large {
        width: 50%;
    }

    .masonry-wrapper[data-col-sm="4"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="5"] .grid-item,
    .masonry-wrapper[data-col-sm="5"] .grid-sizer {
        width: 20%;
    }

    .masonry-wrapper[data-col-sm="5"] .grid-item.wide, .masonry-wrapper[data-col-sm="5"] .grid-item.large {
        width: 40%;
    }

    .masonry-wrapper[data-col-sm="5"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="6"] .grid-item,
    .masonry-wrapper[data-col-sm="6"] .grid-sizer {
        width: 16.66667%;
    }

    .masonry-wrapper[data-col-sm="6"] .grid-item.wide, .masonry-wrapper[data-col-sm="6"] .grid-item.large {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-sm="6"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="7"] .grid-item,
    .masonry-wrapper[data-col-sm="7"] .grid-sizer {
        width: 14.28571%;
    }

    .masonry-wrapper[data-col-sm="7"] .grid-item.wide, .masonry-wrapper[data-col-sm="7"] .grid-item.large {
        width: 28.57143%;
    }

    .masonry-wrapper[data-col-sm="7"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="8"] .grid-item,
    .masonry-wrapper[data-col-sm="8"] .grid-sizer {
        width: 12.5%;
    }

    .masonry-wrapper[data-col-sm="8"] .grid-item.wide, .masonry-wrapper[data-col-sm="8"] .grid-item.large {
        width: 25%;
    }

    .masonry-wrapper[data-col-sm="8"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="9"] .grid-item,
    .masonry-wrapper[data-col-sm="9"] .grid-sizer {
        width: 11.11111%;
    }

    .masonry-wrapper[data-col-sm="9"] .grid-item.wide, .masonry-wrapper[data-col-sm="9"] .grid-item.large {
        width: 22.22222%;
    }

    .masonry-wrapper[data-col-sm="9"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .masonry-wrapper[data-col-sm="10"] .grid-item,
    .masonry-wrapper[data-col-sm="10"] .grid-sizer {
        width: 10%;
    }

    .masonry-wrapper[data-col-sm="10"] .grid-item.wide, .masonry-wrapper[data-col-sm="10"] .grid-item.large {
        width: 20%;
    }

    .masonry-wrapper[data-col-sm="10"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="1"] .grid-item,
    .masonry-wrapper[data-col-md="1"] .grid-sizer {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="2"] .grid-item,
    .masonry-wrapper[data-col-md="2"] .grid-sizer {
        width: 50%;
    }

    .masonry-wrapper[data-col-md="2"] .grid-item.wide, .masonry-wrapper[data-col-md="2"] .grid-item.large {
        width: 100%;
    }

    .masonry-wrapper[data-col-md="2"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="3"] .grid-item,
    .masonry-wrapper[data-col-md="3"] .grid-sizer {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-md="3"] .grid-item.wide, .masonry-wrapper[data-col-md="3"] .grid-item.large {
        width: 66.66667%;
    }

    .masonry-wrapper[data-col-md="3"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="4"] .grid-item,
    .masonry-wrapper[data-col-md="4"] .grid-sizer {
        width: 25%;
    }

    .masonry-wrapper[data-col-md="4"] .grid-item.wide, .masonry-wrapper[data-col-md="4"] .grid-item.large {
        width: 50%;
    }

    .masonry-wrapper[data-col-md="4"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="5"] .grid-item,
    .masonry-wrapper[data-col-md="5"] .grid-sizer {
        width: 20%;
    }

    .masonry-wrapper[data-col-md="5"] .grid-item.wide, .masonry-wrapper[data-col-md="5"] .grid-item.large {
        width: 40%;
    }

    .masonry-wrapper[data-col-md="5"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="6"] .grid-item,
    .masonry-wrapper[data-col-md="6"] .grid-sizer {
        width: 16.66667%;
    }

    .masonry-wrapper[data-col-md="6"] .grid-item.wide, .masonry-wrapper[data-col-md="6"] .grid-item.large {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-md="6"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="7"] .grid-item,
    .masonry-wrapper[data-col-md="7"] .grid-sizer {
        width: 14.28571%;
    }

    .masonry-wrapper[data-col-md="7"] .grid-item.wide, .masonry-wrapper[data-col-md="7"] .grid-item.large {
        width: 28.57143%;
    }

    .masonry-wrapper[data-col-md="7"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="8"] .grid-item,
    .masonry-wrapper[data-col-md="8"] .grid-sizer {
        width: 12.5%;
    }

    .masonry-wrapper[data-col-md="8"] .grid-item.wide, .masonry-wrapper[data-col-md="8"] .grid-item.large {
        width: 25%;
    }

    .masonry-wrapper[data-col-md="8"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="9"] .grid-item,
    .masonry-wrapper[data-col-md="9"] .grid-sizer {
        width: 11.11111%;
    }

    .masonry-wrapper[data-col-md="9"] .grid-item.wide, .masonry-wrapper[data-col-md="9"] .grid-item.large {
        width: 22.22222%;
    }

    .masonry-wrapper[data-col-md="9"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .masonry-wrapper[data-col-md="10"] .grid-item,
    .masonry-wrapper[data-col-md="10"] .grid-sizer {
        width: 10%;
    }

    .masonry-wrapper[data-col-md="10"] .grid-item.wide, .masonry-wrapper[data-col-md="10"] .grid-item.large {
        width: 20%;
    }

    .masonry-wrapper[data-col-md="10"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="1"] .grid-item,
    .masonry-wrapper[data-col-lg="1"] .grid-sizer {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="2"] .grid-item,
    .masonry-wrapper[data-col-lg="2"] .grid-sizer {
        width: 50%;
    }

    .masonry-wrapper[data-col-lg="2"] .grid-item.wide, .masonry-wrapper[data-col-lg="2"] .grid-item.large {
        width: 100%;
    }

    .masonry-wrapper[data-col-lg="2"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="3"] .grid-item,
    .masonry-wrapper[data-col-lg="3"] .grid-sizer {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-lg="3"] .grid-item.wide, .masonry-wrapper[data-col-lg="3"] .grid-item.large {
        width: 66.66667%;
    }

    .masonry-wrapper[data-col-lg="3"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="4"] .grid-item,
    .masonry-wrapper[data-col-lg="4"] .grid-sizer {
        width: 25%;
    }

    .masonry-wrapper[data-col-lg="4"] .grid-item.wide, .masonry-wrapper[data-col-lg="4"] .grid-item.large {
        width: 50%;
    }

    .masonry-wrapper[data-col-lg="4"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="5"] .grid-item,
    .masonry-wrapper[data-col-lg="5"] .grid-sizer {
        width: 20%;
    }

    .masonry-wrapper[data-col-lg="5"] .grid-item.wide, .masonry-wrapper[data-col-lg="5"] .grid-item.large {
        width: 40%;
    }

    .masonry-wrapper[data-col-lg="5"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="6"] .grid-item,
    .masonry-wrapper[data-col-lg="6"] .grid-sizer {
        width: 16.66667%;
    }

    .masonry-wrapper[data-col-lg="6"] .grid-item.wide, .masonry-wrapper[data-col-lg="6"] .grid-item.large {
        width: 33.33333%;
    }

    .masonry-wrapper[data-col-lg="6"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="7"] .grid-item,
    .masonry-wrapper[data-col-lg="7"] .grid-sizer {
        width: 14.28571%;
    }

    .masonry-wrapper[data-col-lg="7"] .grid-item.wide, .masonry-wrapper[data-col-lg="7"] .grid-item.large {
        width: 28.57143%;
    }

    .masonry-wrapper[data-col-lg="7"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="8"] .grid-item,
    .masonry-wrapper[data-col-lg="8"] .grid-sizer {
        width: 12.5%;
    }

    .masonry-wrapper[data-col-lg="8"] .grid-item.wide, .masonry-wrapper[data-col-lg="8"] .grid-item.large {
        width: 25%;
    }

    .masonry-wrapper[data-col-lg="8"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="9"] .grid-item,
    .masonry-wrapper[data-col-lg="9"] .grid-sizer {
        width: 11.11111%;
    }

    .masonry-wrapper[data-col-lg="9"] .grid-item.wide, .masonry-wrapper[data-col-lg="9"] .grid-item.large {
        width: 22.22222%;
    }

    .masonry-wrapper[data-col-lg="9"] .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .masonry-wrapper[data-col-lg="10"] .grid-item,
    .masonry-wrapper[data-col-lg="10"] .grid-sizer {
        width: 10%;
    }

    .masonry-wrapper[data-col-lg="10"] .grid-item.wide, .masonry-wrapper[data-col-lg="10"] .grid-item.large {
        width: 20%;
    }

    .masonry-wrapper[data-col-lg="10"] .grid-item.extra-large {
        width: 100%;
    }
}

.masonry-wrapper[data-gap="5"] {
    margin: -3px;
}

.masonry-wrapper[data-gap="5"] .grid-item .grid-item__content-wrapper {
    margin: 3px;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
}

.masonry-wrapper[data-gap="10"] {
    margin: -5px;
}

.masonry-wrapper[data-gap="10"] .grid-item .grid-item__content-wrapper {
    margin: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

.masonry-wrapper[data-gap="15"] {
    margin: -8px;
}

.masonry-wrapper[data-gap="15"] .grid-item .grid-item__content-wrapper {
    margin: 8px;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
}

.masonry-wrapper[data-gap="20"] {
    margin: -10px;
}

.masonry-wrapper[data-gap="20"] .grid-item .grid-item__content-wrapper {
    margin: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
}

.masonry-wrapper[data-gap="25"] {
    margin: -13px;
}

.masonry-wrapper[data-gap="25"] .grid-item .grid-item__content-wrapper {
    margin: 13px;
    top: 13px;
    right: 13px;
    bottom: 13px;
    left: 13px;
}

.masonry-wrapper[data-gap="30"] {
    margin: -15px;
}

.masonry-wrapper[data-gap="30"] .grid-item .grid-item__content-wrapper {
    margin: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
}

/*1. RESET AND TYPHOGRAPHY */
html {
    font-family: ModamWeb, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

progress {
    vertical-align: baseline;
}

template,
[hidden] {
    display: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: inherit;
}

b,
strong {
    font-weight: bolder;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

figure {
    margin: 1em 40px;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input,
select,
textarea {
    font: inherit;
    margin: 0;
}

optgroup {
    font-weight: bold;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    /* 3 */
    white-space: normal;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

/**
*
*	Name:				Modam Fonts
*	Version:			1.0
*	Author:			Naser Khadem
*	Created on:		April 22, 2023
*	Updated on:		April 22, 2023
*	Website:			http://fontiran.com
*	Copyright:			Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت مُدام یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
Modam fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*	
**/

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 200;
	src: url('/fonts/webfonts/ModamWeb-ExtraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-ExtraLight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/webfonts/ModamWeb-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/webfonts/ModamWeb-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 500;
	src: url('/fonts/webfonts/ModamWeb-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/webfonts/ModamWeb-Semibold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Semibold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: bold;
	src: url('/fonts/webfonts/ModamWeb-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 800;
	src: url('/fonts/webfonts/ModamWeb-ExtraBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-ExtraBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
	font-family: ModamWeb;
	font-style: normal;
	font-weight: 900;
	src: url('/fonts/webfonts/ModamWeb-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('/fonts/webfonts/ModamWeb-Black.woff') format('woff')  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}


/* Generated by script */
@font-face {
    font-family: "Vazirmatn";
    src: url("/fonts/ttf/Vazirmatn-Thin.ttf") format("truetype"), url('/fonts/webfonts/Vazirmatn-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-ExtraLight.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-Light.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-Regular.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-Medium.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-SemiBold.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-Bold.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-ExtraBold.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vazirmatn";
    src: url('/fonts/ttf/Vazirmatn-Black.ttf') format("truetype"), url('/fonts/webfonts/Vazirmatn-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}




html {
    font-size: 62.5%;
}

body {
    font-size: 14px;
    font-family: "Vazirmatn", serif;
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    font-family: ModamWeb, serif;
    position: relative;
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 700;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p {
    font-size: 16px;
    line-height: 1.6em;
    color: #555;
    font-family: "Vazirmatn", serif;
}

p span {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
}

a {
    font-family: "Vazirmatn", serif;
    position: relative;
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

a:hover {
    color: #ce873a;
}

a,
input,
textarea,
button,
select {
    outline: none;
    font-size: 16px;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
    text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

img {
    max-width: 100%;
}

iframe {
    border: 0;
    max-width: 100%;
    width: 100%;
}

figure {
    margin: 0;
}

.ps-document ul, .ps-document ol {
    margin-bottom: 10px;
}

.ps-document ul ul, .ps-document ul ol, .ps-document ol ul, .ps-document ol ol {
    margin-bottom: 0;
}

.ps-document ul li, .ps-document ol li {
    color: #555;
    font-size: 14px;
    line-height: 1.6em;
}

.ps-document h5 {
    font-size: 16px;
    font-weight: 600;
}

.ps-document p {
    margin-bottom: 4rem;
    line-height: 1.8em;
    color: #555;
}

.ps-document p i {
    font-family: "Vazirmatn", serif;
}

.ps-document p strong {
    font-weight: 600;
    color: #555;
}

.ps-document p strong i {
    font-weight: 400;
    color: #000;
}

.ps-document p strong.large {
    font-size: 24px;
}

.ps-document blockquote {
    position: relative;
    margin-bottom: 3rem;
    padding: 3rem 2rem;
    padding-left: 6rem;
    background-color: #f1f1f1;
}

.ps-document blockquote:before {
    content: "“";
    position: absolute;
    top: 10px;
    left: 30px;
    font-family: "Vazirmatn", serif;
    font-size: 5rem;
    color: #555;
}

.ps-document blockquote p {
    margin-bottom: 0;
    font-family: "Vazirmatn", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8em;
    color: #555;
    font-style: italic;
}

.ps-instock {
    color: #f6663f;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

/*2. ELEMENTS */
html, body {
    min-height: 100%;
    position: relative;
}

@media (max-width: 767px) {
    .ps-col-tiny .col-xs-12 {
        width: 50%;
    }
}

@media (max-width: 479px) {
    .ps-col-tiny .col-xs-12 {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

body {
    overflow-x: hidden;
}

.ps-list--social {
    margin: 0;
    padding: 0;
}

.ps-list--social li {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}

.ps-list--social li a {
    display: inline-block;
    font-size: 18px;
    color: #555;
}

.ps-list--social li a:hover {
    color: #ce873a;
}

.ps-list--social li a.facebook i {
    color: #3b5998;
}

.ps-list--social li a.twitter i {
    color: #0084b4;
}

.ps-list--social li a.google-plus i {
    color: #FF0000;
}

.ps-list--social li a.instagram i {
    color: #C32AA3;
}

.ps-list--social-color {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ps-list--social-color li {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}

.ps-list--social-color li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 20px;
    width: 38px;
    height: 38px;
    color: #ffffff;
}

.ps-list--social-color li a.facebook {
    background-color: #1b4e9b;
}

.ps-list--social-color li a.facebook:hover {
    background-color: #296fda;
}

.ps-list--social-color li a.twitter {
    background-color: #00aeef;
}

.ps-list--social-color li a.twitter:hover {
    background-color: #3dcaff;
}

.ps-list--social-color li a.google-plus {
    background-color: #cc0000;
}

.ps-list--social-color li a.google-plus:hover {
    background-color: #ff1a1a;
}

.ps-list--social-color li a.linkedin {
    background-color: #2d567f;
}

.ps-list--social-color li a.linkedin:hover {
    background-color: #417cb7;
}

.ps-list--social-color li a.feed {
    background-color: #f86a02;
}

.ps-list--social-color li a.feed:hover {
    background-color: #fe9549;
}

.ps-list--checked li {
    margin-bottom: 20px;
}

.ps-list--checked li a {
    position: relative;
    display: block;
    padding-left: 30px;
    font-size: 14px;
    color: #313131;
}

.ps-list--checked li a:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 20px;
    height: 20px;
    border: solid 1px #8d8d8d;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.ps-list--checked li a:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 8px;
    color: #fff;
    width: 6px;
    height: 10px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    visibility: hidden;
    opacity: 0;
}

.ps-list--checked li a:hover:before {
    background-color: #ce873a;
    border-color: #ce873a;
}

.ps-list--checked li a:hover:after {
    visibility: visible;
    opacity: 1;
}

.ps-list--checked li.current a:before {
    background-color: #ce873a;
    border-color: #ce873a;
}

.ps-list--checked li.current a:after {
    visibility: visible;
    opacity: 1;
}

.ps-list--checked.ps-list--checked-circle li a:before {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.ps-list--checked.ps-list--checked-circle li a:hover:before {
    background-color: #a7a7a7;
    border-color: #a7a7a7;
}

.ps-list--arrow a {
    position: relative;
    display: block;
    padding-left: 15px;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #555555;
}

.ps-list--arrow a:before {
    content: "\f105";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    font-family: FontAwesome;
}

.ps-list--arrow a:hover {
    color: #ce873a;
}

.ps-list--arrow a:hover .circle {
    background-color: #222222;
    border-color: #222222;
}

.ps-list--arrow a:hover .circle:before {
    color: #fff;
    visibility: visible;
    opacity: 1;
}

.ps-list--arrow li {
    margin-bottom: 20px;
}

.ps-list--arrow li.current a {
    color: #222222;
}

.ps-list--arrow li.current .circle {
    background-color: #222222;
}

.ps-list--arrow li.current .circle:before {
    visibility: visible;
    opacity: 1;
    color: #fff;
}

.ps-list--dot {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    list-style-type: none;
}

.ps-list--dot li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    color: #000000;
    line-height: 20px;
}

.ps-list--dot li:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #ce873a;
    border-radius: 50px;
}

.ps-list--plus li {
    margin: 10px 0;
}

.ps-list--plus li a {
    display: block;
    position: relative;
    padding-left: 15px;
    line-height: 20px;
}

.ps-list--plus li a:before {
    content: '+';
    position: absolute;
    top: 0;
    left: 0;
    line-height: 20px;
}

button {
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.menu-toggle, .ps-btn--hamburger {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50px;
    background-color: rgba(206, 135, 58, 0.6);
}

.menu-toggle span, .menu-toggle:before, .menu-toggle:after, .ps-btn--hamburger span, .ps-btn--hamburger:before, .ps-btn--hamburger:after {
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #fff;
    z-index: 100;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.menu-toggle span, .ps-btn--hamburger span {
    left: 15px;
    width: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.menu-toggle:before, .menu-toggle:after, .ps-btn--hamburger:before, .ps-btn--hamburger:after {
    content: '';
    left: 15px;
}

.menu-toggle:before, .ps-btn--hamburger:before {
    top: 16px;
}

.menu-toggle:after, .ps-btn--hamburger:after {
    bottom: 16px;
}

.menu-toggle:hover, .ps-btn--hamburger:hover {
    cursor: pointer;
}

.menu-toggle.active span, .ps-btn--hamburger.active span {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
}

.menu-toggle.active:before, .menu-toggle.active:after, .ps-btn--hamburger.active:before, .ps-btn--hamburger.active:after {
    top: 50%;
    bottom: auto;
    width: 20px;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: #fc354c;
}

.menu-toggle.active:before, .ps-btn--hamburger.active:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-toggle.active:after, .ps-btn--hamburger.active:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@media (max-width: 1199px) {
    .menu-toggle, .ps-btn--hamburger {
        display: inline-block;
    }
}

.ps-btn, button.ps-btn {
    display: inline-block;
    padding: 15px 45px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    font-weight: 600;
    border-radius: 50px;
    background-color: #ce873a;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
}

.ps-btn--rounded, button.ps-btn--rounded {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.ps-btn--curve, button.ps-btn--curve {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
}

.ps-btn--outline, button.ps-btn--outline {
    padding: 15px 36px;
    font-size: 14px;
    color: #464646;
    border-radius: 100px;
    border: 1px solid #464646;
    text-transform: uppercase;
    background-color: transparent;
}

.ps-btn--outline.white, button.ps-btn--outline.white {
    padding: 15px 36px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #ffffff;
    text-transform: uppercase;
    background-color: transparent;
}

.ps-btn--outline:hover, button.ps-btn--outline:hover {
    background-color: #ce873a;
    border-color: #ce873a;
    color: #ffffff;
}

.ps-btn--fullwidth, button.ps-btn--fullwidth {
    width: 100%;
    text-align: center;
}

.ps-btn--sm, button.ps-btn--sm {
    padding: .5rem 2rem;
    font-size: 1.2rem;
}

.ps-btn--sm.ps-btn--curve, button.ps-btn--sm.ps-btn--curve {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px
}

.ps-btn--lg, button.ps-btn--lg {
    padding: 1.5rem 8rem;
    display: flex!important;
    align-items: center;
    border-color: #ce873a;
    background-color: #fff;

}

.ps-btn--xl, button.ps-btn--xl {
    padding: 2rem 6rem;
    font-size: 1.6rem;
}

.ps-btn.ps-btn--reverse, button.ps-btn.ps-btn--reverse {
    background-color: #576391;
}

.ps-btn.ps-btn--reverse:hover, button.ps-btn.ps-btn--reverse:hover {
    background-color: #222222;
}

.ps-btn.ps-btn--gray, button.ps-btn.ps-btn--gray {
    background-color: #e5e5e5;
    color: #000000;
    font-size: 1.6rem;
}

.ps-btn.ps-btn--black, button.ps-btn.ps-btn--black {
    background-color: #000;
}

.ps-btn.ps-btn--black.ps-btn--outline, button.ps-btn.ps-btn--black.ps-btn--outline {
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
}

.ps-btn.ps-btn--black.ps-btn--outline:hover, button.ps-btn.ps-btn--black.ps-btn--outline:hover {
    background-color: #000;
    color: #ffffff;
}

.ps-btn:hover, .ps-btn:active, button.ps-btn:hover, button.ps-btn:active {
    color: #fff;
    background-color: #ab6d2a;
}

.ps-btn:hover.ps-btn--black, .ps-btn:active.ps-btn--black, button.ps-btn:hover.ps-btn--black, button.ps-btn:active.ps-btn--black {
    background-color: #ce873a;
}

.ps-btn--close {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.ps-btn--close:before, .ps-btn--close:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 50%;
    background-color: #9f9a98;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.ps-btn--close:before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    -o-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}

.ps-btn--close:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -moz-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    -o-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.ps-btn--close:hover {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.ps-btn--close:hover:before, .ps-btn--close:hover:after {
    background-color: black !important;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

#back2top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 45px;
    height: 45px;
    z-index: 10000;
    background-color: transparent;
    border: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: none;
}

#back2top i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #000;
    z-index: 10001;
    font-size: 30px;
}

#back2top:hover {
    cursor: pointer;
    background-color: #e6e6e6;
}

#back2top.active {
    bottom: 30px;
    visibility: visible;
    opacity: 1;
}

@media (max-width: 1199px) {
    #back2top {
        display: none;
    }
}

.form-control {
    outline: none;
    height: 50px;
    font-size: 18px;
    padding: 0 20px;
    border: none;
    height: 50px;
    border-radius: 0;
    border: 1px solid #ccc;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    background-color: transparent;
}

.form-control::-webkit-input-placeholder {
    opacity: 1;
    font-size: 18px;
    font-family: ModamWeb;
}

.form-control::-moz-placeholder {
    opacity: 1;
    font-size: 18px;
    font-family: ModamWeb;
}

.form-control:-moz-placeholder {
    opacity: 1;
    font-size: 18px;
    font-family: ModamWeb;
}

.form-control:-ms-input-placeholder {
    opacity: 1;
    font-size: 18px;
    font-family: ModamWeb;
}

.form-control:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 #000;
    box-shadow: 0 0 0 #000;
    border-color: #ce873a;
}

textarea.form-control {
    height: auto;
    padding: 2rem;
    resize: none;
}

.ps-input--has-icon {
    position: relative;
}

.ps-input--has-icon > i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.ps-select {
    display: inline-block;
    min-width: 120px;
    cursor: pointer;
}

.ps-select .selectize-input {
    vertical-align: top;
}

.ps-number {
    position: relative;
}

.ps-number span {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 50%;
    border-left: 1px solid #979797;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-number span:before {
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #000;
}

.ps-number span.up {
    border-bottom: 1px solid #979797;
}

.ps-number span.up:before {
    content: "\f106";
}

.ps-number span.down {
    top: auto;
    bottom: 0;
}

.ps-number span.down:before {
    content: "\f107";
}

.ps-number span:hover {
    cursor: pointer;
    background-color: #ce873a;
}

.ps-number span:hover:before {
    color: #fff;
}

.ps-checkbox {
    position: relative;
    display: block;
}

.ps-checkbox > input {
    position: absolute;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ps-checkbox label {
    margin-bottom: 0;
    position: relative;
    padding-left: 30px;
    font-family: ModamWeb, serif;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    cursor: pointer;
}

.ps-checkbox label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    height: 20px;
    width: 20px;
    z-index: 10;
    border: 1px solid #000;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-checkbox label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: 2px solid #fff;
    border-top: none;
    border-left: none;
    z-index: 10;
    opacity: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-checkbox input[type=checkbox]:checked ~ label:before {
    background-color: #222222;
    border-color: #222222;
}

.ps-checkbox input[type=checkbox]:checked ~ label:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 1;
}

.ps-checkbox--circle label {
    font-size: 20px;
    font-weight: 600;
    color: #555;
}

.ps-checkbox--circle label:before {
    border-radius: 50%;
    border-color: #595959;
}

.ps-checkbox--circle input[type=checkbox]:checked ~ label:before {
    background-color: transparent;
}

.ps-checkbox--circle input[type=checkbox]:checked ~ label:after {
    background-color: #ce873a;
    border-radius: 50%;
    border: none;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
}

.ps-checkbox--inline {
    display: inline-block;
    margin-bottom: 1rem;
}

.ps-checkbox--color {
    margin-right: 1rem;
}

.ps-checkbox--color > label {
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    padding-left: 0;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-checkbox--color > label:before {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50%;
}

.ps-checkbox--color > label:after {
    display: none !important;
}

.ps-checkbox--color input[type=radio]:checked ~ label {
    background-color: transparent;
}

.ps-checkbox--color input[type=radio]:checked ~ label:before {
    background-color: transparent;
    border: 1px solid #ccc;
}

.ps-checkbox--color.color-1 label:before {
    background-color: #000000;
}

.ps-checkbox--color.color-1 input[type=checkbox]:checked ~ label:before {
    background-color: #000000;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-2 label:before {
    background-color: #709abf;
}

.ps-checkbox--color.color-2 input[type=checkbox]:checked ~ label:before {
    background-color: #709abf;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-3 label:before {
    background-color: #ad8111;
}

.ps-checkbox--color.color-3 input[type=checkbox]:checked ~ label:before {
    background-color: #ad8111;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-4 label:before {
    background-color: #57d6db;
}

.ps-checkbox--color.color-4 input[type=checkbox]:checked ~ label:before {
    background-color: #57d6db;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-5 label:before {
    background-color: #e5ac10;
}

.ps-checkbox--color.color-5 input[type=checkbox]:checked ~ label:before {
    background-color: #e5ac10;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-6 label:before {
    background-color: #8ce84a;
}

.ps-checkbox--color.color-6 input[type=checkbox]:checked ~ label:before {
    background-color: #8ce84a;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-7 label:before {
    background-color: #c9c9c9;
}

.ps-checkbox--color.color-7 input[type=checkbox]:checked ~ label:before {
    background-color: #c9c9c9;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
    background-color: #e664ef;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
    background-color: #e664ef;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
    background-color: #9d58e2;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
    background-color: #9d58e2;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
    background-color: #ffffff;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--size {
    margin-right: 1rem;
}

.ps-checkbox--size > label {
    margin-bottom: 0;
    padding: 8px 20px;
    border: none;
    color: #222222;
    background-color: #f6f6f6;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-checkbox--size > label:before, .ps-checkbox--size > label:after {
    display: none;
}

.ps-checkbox--size input[type=checkbox]:checked ~ label {
    color: #ffffff;
    background-color: #a7a7a7;
}

.ps-radio {
    position: relative;
}

.ps-radio > input {
    position: absolute;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ps-radio label {
    position: relative;
    margin-bottom: 0;
    padding-left: 30px;
    font-family: ModamWeb, serif;
    color: #737373;
    font-weight: 400;
    cursor: pointer;
}

.ps-radio label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    height: 20px;
    width: 20px;
    z-index: 10;
    border: 1px solid rgba(19, 8, 1, 0.2);
    background-color: #fff;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 50%;
}

.ps-radio label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 15;
    opacity: 0;
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.ps-radio input[type=radio]:checked ~ label:before {
    background-color: transparent;
}

.ps-radio input[type=radio]:checked ~ label:after {
    background-color: #ce873a;
    border-radius: 50%;
    border: none;
    width: 8px;
    height: 8px;
    top: 6px;
    left: 6px;
    opacity: 1;
}

.ps-radio--inline {
    display: inline-block;
    margin-right: 20px;
}

.ps-radio--color {
    margin-right: 1rem;
}

.ps-radio--color > label {
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    padding-left: 0;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-radio--color > label:before {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: none;
}

.ps-radio--color > label:after {
    display: none !important;
}

.ps-radio--color input[type=radio]:checked ~ label {
    background-color: rgba(61, 196, 126, 0.5);
}

.ps-radio--color.color-1 label:before {
    background-color: #35dccb;
}

.ps-radio--color.color-1 input[type=radio]:checked ~ label {
    background-color: rgba(53, 220, 203, 0.5);
}

.ps-radio--color.color-1 input[type=radio]:checked ~ label:before {
    background-color: #21bdad;
}

.ps-radio--color.color-2 label:before {
    background-color: #fec6df;
}

.ps-radio--color.color-2 input[type=radio]:checked ~ label {
    background-color: rgba(254, 198, 223, 0.5);
}

.ps-radio--color.color-2 input[type=radio]:checked ~ label:before {
    background-color: #fd94c3;
}

.ps-radio--color.color-3 label:before {
    background-color: #ffd980;
}

.ps-radio--color.color-3 input[type=radio]:checked ~ label {
    background-color: rgba(255, 217, 128, 0.5);
}

.ps-radio--color.color-3 input[type=radio]:checked ~ label:before {
    background-color: #ffca4d;
}

.ps-color {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;
}

.ps-color--1 {
    background-color: #000;
}

.ps-color--2 {
    background-color: #ad4d4b;
}

.ps-color--3 {
    background-color: #fdb201;
}

.ps-color:last-child {
    margin-right: 0;
}

.tooltip .tooltip-inner {
    font-size: 14px;
}

.form-group {
    margin-bottom: 2.5rem;
}

.form-group > label {
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: #000000;
    line-height: 1em;
}

.form-group--inline {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
}

.form-group--inline > label {
    margin-bottom: 0;
    font-weight: 700;
    min-width: 160px;
    max-width: 160px;
}

.form-group--inline .form-group__content {
    width: 100%;
}

@media (max-width: 1199px) {
    .form-group--inline {
        padding-left: 0;
    }

    .form-group--inline label {
        position: static;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .form-group--inline.textarea label {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@media (max-width: 767px) {
    .form-group--inline {
        display: block;
    }
}

.form-group--number {
    display: inline-block;
    position: relative;
}

.form-group--number button {
    background: none;
    border: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    max-width: 20px;
    color: #000;
    font-size: 30px;
}

.form-group--number button.up {
    right: 12px;
}

.form-group--number button.up:before, .form-group--number button.up:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: '';
    width: 14px;
    height: 1px;
    background-color: #ce873a;
}

.form-group--number button.up:before {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    -moz-transform: translate(-50%, -50%) rotate(90deg);
    -ms-transform: translate(-50%, -50%) rotate(90deg);
    -o-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.form-group--number button.up:after {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number button.down {
    left: 12px;
}

.form-group--number button.down:before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: '';
    width: 14px;
    height: 1px;
    background-color: #ce873a;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number .form-control {
    border: 1px solid #ccc;
    height: 45px;
    padding: 0 25px;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    background-color: transparent;
}

.form-group--number .form-control::-webkit-input-placeholder {
    color: #000;
}

.form-group--number .form-control::-moz-placeholder {
    color: #000;
}

.form-group--number .form-control:-moz-placeholder {
    color: #000;
}

.form-group--number .form-control:-ms-input-placeholder {
    color: #000;
}

.form-group--select {
    position: relative;
}

.form-group--select i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
}

.form-group--select select {
    height: 40px;
    padding: 0 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.form-group--nest {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.form-group--nest .ps-btn {
    border-radius: 0 4px 4px 0;
    padding-left: 25px;
    padding-right: 25px;
}

.form-group--nest input {
    border-radius: 4px 0 0 4px;
    border-color: #ccc;
    border-right: none;
}

.form-group--icon {
    position: relative;
}

.form-group--icon i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
}

.form-group--icon i:before {
    font-size: 10px;
}

.form-group--icon .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.ps-dropdown {
    position: relative;
    display: inline-block;
}

.ps-dropdown > a {
    position: relative;
    display: block;
    padding-right: 20px;
}

.ps-dropdown > a:after {
    content: "\e93a";
    font: normal normal normal 12px/1 linearicons;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
}

.ps-dropdown ul {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    min-width: 120px;
    max-width: 200px;
    width: 100%;
    padding: 4px 0;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
    -moz-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
    -ms-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
    box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
    visibility: hidden;
    opacity: 0;
    text-align: left;
}

.ps-dropdown ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ps-dropdown ul li a {
    display: block;
    font-size: 1.3rem;
    color: #000;
    padding: 6px 10px;
}

.ps-dropdown:hover ul {
    visibility: visible;
    opacity: 1;
}

.ps-dropdown.open ul {
    visibility: visible;
    opacity: 1;
}

.select2 {
    display: block;
    min-width: 100%;
}

.select2 .select2-selection--single {
    height: auto;
    outline: none;
    border-radius: 0;
}

.select2 .select2-selection--single .select2-selection__rendered {
    line-height: 20px;
    padding: 10px 20px;
}

.select2 .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    height: 20px;
}

.select2 .select2-selection--single .select2-selection__arrow:before {
    content: '\f107';
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.select2 .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2 .select2-selection--multiple {
    min-height: 0;
    border: none !important;
}

.select2 .select2-selection--multiple .select2-selection__rendered {
    margin-bottom: 0;
    padding: 0;
    padding-left: 10px;
    vertical-align: top;
}

.select2 .select2-selection--multiple .select2-selection__rendered input {
    margin-top: 0;
    height: 40px;
    padding: 0 10px;
}

.select2 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    line-height: 20px;
    margin-right: 10px;
    margin-top: 10px;
}

.select2-container .select2-dropdown {
    border-color: #ccc;
}

.select2-container .select2-results li {
    color: #000;
    font-size: 16px;
    padding: 10px 20px;
    background-color: transparent;
}

.form-group--inside {
    position: relative;
}

.form-group--inside button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    background-color: transparent;
    border: none;
    color: #ce873a;
}

.form-group--inside button:hover {
    color: #ab6d2a;
}

.ps-post__thumbnail {
    position: relative;
    overflow: hidden;
}

.ps-post__thumbnail img {
    width: 100%;
    max-width: 100%;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-post__thumbnail .ps-post__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.ps-post .ps-post__thumbnail:hover img {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    -moz-transform: scale3d(1.05, 1.05, 1.05);
    -ms-transform: scale3d(1.05, 1.05, 1.05);
    -o-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
}

.ps-post .ps-post__title {
    display: block;
    margin-bottom: 10px;
    font-size: 30px;
    color: #000;
    line-height: 1.2em;
    font-weight: 700;
}

.ps-post .ps-post__title:hover {
    color: #ce873a;
}

.ps-post .ps-post__meta {
    direction: rtl;
    margin-bottom: 10px;
    font-weight: 600;
    color: #555555;
}

.ps-post .ps-post__meta a {
    color: #ce873a;
}

.ps-post .ps-post__meta a:hover {
    color: #ab6d2a;
}

.ps-post .ps-post__morelink {
    display: inline-block;
    vertical-align: top;
    color: #ce873a;
    font-weight: 700;
    text-transform: uppercase;
}

.ps-post .ps-post__morelink:hover {
    color: #ab6d2a;
}

.ps-post .ps-post__content {
    padding: 30px 0 20px;
}

.ps-post .ps-post__content p:not(.ps-post__meta) {
    margin-bottom: 16px;
    font-size: 18px;
    color: #000;
}

@media (max-width: 1199px) {
    .ps-post .ps-post__title {
        font-size: 24px;
    }
}

@media (max-width: 991px) {
    .ps-post {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    }

    .ps-post > * {
        width: 100%;
    }

    .ps-post .ps-post__thumbnail {
        max-width: 40%;
    }

    .ps-post .ps-post__content {
        padding: 0 0 0 20px;
    }
}

@media (max-width: 767px) {
    .ps-post .ps-post__title {
        font-size: 20px;
    }

    .ps-post .ps-post__morelink {
        font-size: 12px;
    }
}

@media (max-width: 479px) {
    .ps-post {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-post .ps-post__thumbnail {
        max-width: 100%;
    }

    .ps-post .ps-post__content {
        padding: 30px 0 0;
    }

    .ps-post .ps-post__title {
        font-size: 24px;
    }

    .ps-post .ps-post__morelink {
        font-size: 12px;
    }
}

.ps-post--sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-post--sidebar .ps-post__title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.ps-post--sidebar .ps-post__thumbnail {
    max-width: 100px;
}

.ps-post--sidebar .ps-post__content {
    padding-left: 20px;
}

.ps-post--sidebar .ps-post__content p {
    color: #ce873a;
}

@media (max-width: 479px) {
    .ps-post--sidebar .ps-post__thumbnail {
        max-width: 75px;
    }
}

.ps-post--detail {
    padding-bottom: 60px;
}

.ps-blog-comments .rating-post * {
    margin: 0;
    padding: 0;
}

.ps-blog-comments .rating-post .rate {
    height: 32px;
}

.ps-blog-comments .rating-post .rate:not(:checked) > input {
    display: none;
}

.ps-blog-comments .rating-post .rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}

.ps-blog-comments .rating-post .rate:not(:checked) > label:before {
    content: '★ ';
}

.ps-blog-comments .rating-post .rate > input:checked ~ label {
    color: #ffc700;
}

.ps-blog-comments .rating-post .rate:not(:checked) > label:hover,
.ps-blog-comments .rating-post .rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}

.ps-blog-comments .rating-post .rate > input:checked + label:hover,
.ps-blog-comments .rating-post .rate > input:checked + label:hover ~ label,
.ps-blog-comments .rating-post .rate > input:checked ~ label:hover,
.ps-blog-comments .rating-post .rate > input:checked ~ label:hover ~ label,
.ps-blog-comments .rating-post .rate > label:hover ~ input:checked ~ label {
    color: #e5b512;
}


.ps-post--detail .ps-post__tags span {
    margin-right: 10px;
}

.ps-post--detail .ps-post__tags a {
    text-transform: uppercase;
}

.ps-post--detail .ps-post__tags a:after {
    content: ',';
    padding-right: 6px;
}

.ps-post--detail .ps-post__tags a:hover {
    color: #ce873a;
}

.ps-post--detail .ps-post__content p {
    direction: rtl;
    text-align: right;
    margin-bottom: 20px;
    font-size: 19px;
    color: #555;
}

.ps-post--detail .ps-post__content p strong {
    color: #000;
}

@media (max-width: 767px) {
    .ps-post--detail .ps-post__content p {
        font-size: 16px;
    }
}

.ps-post--detail-header h1 {
    font-size: 32px;
    font-weight: 700;
}

.ps-post--detail-header .ps-post__meta span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 16px;
}

.ps-post--detail-header .ps-post__meta span a {
    color: #ce873a;
}

.ps-post--detail-header .ps-post__meta span:before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    height: 15px;
    width: 1px;
}


.ps-post--detail-header .ps-post__meta span:last-child:before {
    display: none;
}

@media (min-width: 992px) {
    .ps-post--detail-header h1 {
        font-size: 36px;
    }
}

.pagination {
    margin: 0;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 0;
}

.pagination li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.pagination li > a ,.pagination li > button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 30;
    color: #999;
    height: 45px;
    width: 45px;
    border: none;
    color: #898989;
    font-weight: 600;
    vertical-align: middle;
    font-size: 18px;
    background-color: transparent;
    border-radius: 50px;
}

.pagination li > a i ,.pagination li > button i {
    font-size: 10px;
    font-weight: 600;
    margin-left: 4px;
}

.pagination li > a:hover , .pagination li > button:hover {
    background-color: #7f462c;
    color: #ffffff;
}

.pagination li.active {
    border: none;
}

.pagination li.active a ,.pagination li.active button {
    background-color: #7f462c;
    color: #ffffff;
}

/*.pagination li:first-child > a, .pagination li:last-child > a {*/
/*    background-color: transparent;*/
/*}*/

.pagination li:first-child > a:hover, .pagination li:last-child > a:hover ,.pagination li:first-child > button:hover, .pagination li:last-child > button:hover {
    background-color: #7f462c;
    color: #ffffff;
}

.pagination li:last-child {
    margin-right: 0;
}

/*.pagination li:last-child > a {*/
/*    background-color: transparent;*/
/*}*/

.ps-pagination {
    text-align: center;
}

.ps-pagination::after {
    clear: both;
    content: "";
    display: table;
}

@media (max-width: 767px) {
    .ps-pagination .pagination > li {
        margin-bottom: 10px;
    }

    .ps-pagination .pagination > li > a .ps-pagination .pagination > li > button {
        min-width: 40px;
        line-height: 40px;
        font-size: 1.4rem;
    }
}

.ps-breadcrumb {
    padding: 10px 0;
    background-color: transparent;
}

.ps-breadcrumb .breadcrumb {
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.ps-breadcrumb .breadcrumb li {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}

.ps-breadcrumb .breadcrumb li:before {
    content: "\f105";
    font-family: FontAwesome;
    margin: 0 5px;
    color: #ce873a;
}

.ps-breadcrumb .breadcrumb li:first-child {
    padding-left: 0;
}

.ps-breadcrumb .breadcrumb li:first-child:before {
    display: none;
}

.ps-breadcrumb .breadcrumb a {
    line-height: 20px;
    color: #ce873a;
}

.ps-breadcrumb .breadcrumb a:hover {
    color: #ce873a;
}

.ps-breadcrumb .breadcrumb a i {
    margin-right: 5px;
}

@media (max-width: 991px) {
    .ps-breadcrumb {
        position: relative;
    }

    .ps-breadcrumb .breadcrumb li {
        font-size: 12px;
    }

    .ps-breadcrumb .breadcrumb a {
        font-size: 12px;
    }
}

.ps-breadcrumb--2 {
    text-align: center;
}

.ps-breadcrumb--2 .breadcrumb {
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.ps-breadcrumb--2 .breadcrumb li {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: #ce873a;
}

.ps-breadcrumb--2 .breadcrumb li:before {
    content: "/";
    margin: 0 5px;
}

.ps-breadcrumb--2 .breadcrumb li:first-child {
    padding-left: 0;
}

.ps-breadcrumb--2 .breadcrumb li:first-child:before {
    display: none;
}

.ps-breadcrumb--2 .breadcrumb a {
    line-height: 20px;
    color: #000;
}

.ps-breadcrumb--2 .breadcrumb a:hover {
    color: #ce873a;
}

.ps-breadcrumb--2 .breadcrumb a i {
    margin-right: 5px;
}

.ps-block--testimonial {
    text-align: center;
}

.ps-block--testimonial .ps-block__header {
    padding-bottom: 40px;
    text-align: center;
}

.ps-block--testimonial .ps-block__header img {
    display: inline-block;
    max-width: 70px;
    border-radius: 50%;
    margin: 0 auto;
}

.ps-block--testimonial .ps-block__content {
    max-width: 750px;
    margin: 0 auto 30px;
}

.ps-block--testimonial .ps-block__content p {
    font-size: 30px;
    font-style: italic;
    color: #000;
}

.ps-block--testimonial .ps-block__content p .hightlight {
    color: #ce873a;
}

.ps-block--testimonial .ps-block__footer {
    position: relative;
    padding-top: 25px;
}

.ps-block--testimonial .ps-block__footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 270px;
    height: 1px;
    width: 100%;
    background-color: #ce873a;
}

.ps-block--testimonial .ps-block__footer p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
}

@media (max-width: 991px) {
    .ps-block--testimonial .ps-block__content p {
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    .ps-block--testimonial .ps-block__content p {
        font-size: 20px;
    }
}

@media (max-width: 479px) {
    .ps-block--testimonial .ps-block__content p {
        font-size: 16px;
    }
}

.ps-block--product-menu .ps-tab-list {
    padding: 30px 0;
    text-align: right;
}

.ps-block--product-menu .ps-tab-list li {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 1px dashed #50332c;
    vertical-align: top;
}

.ps-block--product-menu .ps-tab-list li a {
    display: block;
    color: #ce873a;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 30px;
}

.ps-block--product-menu .ps-tab-list li a i {
    font-size: 30px;
    vertical-align: middle;
    margin-right: 20px;
    position: relative;
    top: -2px;
}

.ps-block--product-menu .ps-tab-list li a:hover {
    color: #ffffff;
}

.ps-block--product-menu .ps-tab-list li a:hover i {
    color: inherit;
}

.ps-block--product-menu .ps-tab-list li a:hover i:before {
    color: inherit;
}

.ps-block--product-menu .ps-tab-list li:last-child {
    
    border-right: none;
}

.ps-block--product-menu .ps-tab-list li.active a {
    color: #ffffff;
}

.ps-block--product-menu .ps-tab-list li.active a i {
    color: inherit;
}

.ps-block--product-menu .ps-tab-list li.active a i:before {
    color: inherit;
}

.ps-block--product-menu .ps-product--horizontal {
    border-bottom: 1px dashed #392e2f;
    padding: 15px 0;
    text-align: right;
}

.ps-block--product-menu .ps-product--horizontal:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.ps-block--product-menu .ps-block__header {
    margin-bottom: 30px;
    border-top: 1px solid #443530;
    border-bottom: 1px solid #443530;
}

@media (max-width: 991px) {
    .ps-block--product-menu .ps-product--horizontal:last-child {
        border-bottom: 1px dashed #392e2f;
    }

    .ps-block--product-menu .ps-tab-list {
        padding: 20px 0;
        text-align: center;
    }

    .ps-block--product-menu .ps-tab-list li a {
        font-size: 18px;
    }

    .ps-block--product-menu .ps-tab-list li a i {
        display: none;
    }
}

@media (max-width: 479px) {
    .ps-block--product-menu .ps-tab-list {
        text-align: center;
    }

    .ps-block--product-menu .ps-tab-list li {
        display: block;
        margin-right: 0;
        padding: 5px 0;
        border-right: none;
    }

    .ps-block--product-menu .ps-tab-list li a {
        font-size: 20px;
    }
}

.ps-block--upcomming-event .ps-block__content {
    padding-bottom: 40px;
    text-align: center;
}

.ps-block--upcomming-event .ps-block__content h4 {
    font-size: 30px;
    color: #ffffff;
    font-weight: 700;
}

.ps-block--upcomming-event .ps-block__content h5 {
    margin-bottom: 20px;
    font-style: italic;
    color: #ce873a;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.ps-block--upcomming-event .ps-block__content p {
    color: #ffffff;
}

.ps-block--upcomming-event .ps-countdown {
    text-align: center;
}

.ps-block--upcomming-event .ps-countdown li {
    position: relative;
    display: inline-block;
    padding-right: 30px;
    margin-right: 30px;
}

.ps-block--upcomming-event .ps-countdown li:after {
    content: ':';
    position: absolute;
    top: 0;
    right: 0;
    font-size: 48px;
    color: #ffffff;
}

.ps-block--upcomming-event .ps-countdown li span {
    font-size: 48px;
    color: #ffffff;
}

.ps-block--upcomming-event .ps-countdown li p {
    margin-bottom: 0;
    font-size: 14px;
    color: #ce873a;
    text-transform: uppercase;
    font-weight: 700;
}

.ps-block--upcomming-event .ps-countdown li:last-child {
    padding-right: 0;
    margin-right: 0;
}

.ps-block--upcomming-event .ps-countdown li:last-child:after {
    display: none;
}

.ps-block--upcomming-event .ps-block__footer {
    text-align: center;
    padding-top: 50px;
    border-top: 1px solid #ce873a;
}

.ps-block--upcomming-event .ps-block__footer .ps-countdown {
    margin-bottom: 60px;
}

@media (max-width: 767px) {
    .ps-block--upcomming-event .ps-countdown li span {
        font-size: 36px;
    }
}

@media (max-width: 479px) {
    .ps-block--upcomming-event h4 {
        font-size: 26px;
    }

    .ps-block--upcomming-event .ps-countdown li:after {
        font-size: 24px;
    }

    .ps-block--upcomming-event .ps-countdown li span {
        font-size: 24px;
    }
}

.ps-block--award {
    text-align: center;
}


.ps-block--award .ps-block__header img {
    margin-bottom: 40px;
}

.ps-block--award .ps-block__header h4 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}

.ps-block--award .ps-block__header p {
    margin-bottom: 0;
    font-style: italic;
    font-weight: 700;
    font-size: 20px;
    color: #ce873a;
    line-height: 1em;
}

.ps-block--award .ps-block__content p {
    font-size: 19px;
    color: #ffffff;
    line-height: 1.4em;
}

@media (max-width: 479px) {


    .ps-block--award .ps-block__content {
        padding: 0 30px;
    }
}

.ps-block--good-baker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ps-block--good-baker > * {
    max-width: 50%;
}

.ps-block--good-baker .ps-block__right h4 {
    font-size: 30px;
    font-weight: 700;
}

.ps-block--good-baker .ps-block__right h5 {
    margin-bottom: 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: #ce873a;
    font-weight: 700;
}

.ps-block--good-baker .ps-block__right p {
    color: #555555;
    font-size: 19px;
    line-height: 1.4em;
}

.ps-block--good-baker .ps-block__images {
    padding-top: 50px;
}

.ps-block--good-baker .ps-block__images img {
    display: inline-block;
    margin-right: 70px;
}

.ps-block--good-baker .ps-block__images img:last-child {
    margin-right: 0;
}

@media (max-width: 1199px) {
    .ps-block--good-baker .ps-block__right {
        padding-left: 50px;
    }

    .ps-block--good-baker .ps-block__images img {
        max-height: 80px;
        margin-right: 30px;
    }
}

@media (max-width: 991px) {
    .ps-block--good-baker {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-block--good-baker .ps-block__left {
        text-align: center;
        max-width: 100%;
        margin-bottom: 30px;
    }

    .ps-block--good-baker .ps-block__right {
        max-width: 100%;
        text-align: center;
        padding-left: 0;
    }
}

@media (max-width: 479px) {
    .ps-block--good-baker .ps-block__images img {
        max-height: 60px;
        margin-right: 30px;
    }
}

.ps-block--iconbox {
    text-align: center;
}

.ps-block--iconbox img {
    margin-bottom: 30px;
    min-height: 90px;
}

.ps-block--iconbox h4 {
    font-weight: 700;
    color: #000000;
    font-size: 24px;
}

.ps-block--iconbox p {
    font-size: 19px;
    color: #555555;
}

@media (max-width: 479px) {
    .ps-block--iconbox {
        margin-bottom: 50px;
    }

    .ps-block--iconbox img {
        min-height: 0px;
        max-height: 60px;
    }

    .ps-block--iconbox p {
        font-size: 16px;
    }
}

/*.ps-block--instagram {*/
/*    display: block;*/
/*    position: relative;*/
/*}*/

/*.ps-block--instagram:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    z-index: 10;*/
/*    background-color: rgba(0, 0, 0, 0.5);*/
/*    -webkit-transition: all .4s ease;*/
/*    -o-transition: all .4s ease;*/
/*    transition: all .4s ease;*/
/*    visibility: hidden;*/
/*    opacity: 0;*/
/*}*/

/*.ps-block--instagram i {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    -webkit-transform: translate(-50%, -50%);*/
/*    -moz-transform: translate(-50%, -50%);*/
/*    -ms-transform: translate(-50%, -50%);*/
/*    -o-transform: translate(-50%, -50%);*/
/*    transform: translate(-50%, -50%);*/
/*    z-index: 20;*/
/*    font-size: 20px;*/
/*    color: #ce873a;*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*    visibility: hidden;*/
/*    opacity: 0;*/
/*    text-transform: uppercase;*/
/*}*/

/*.ps-block--instagram:hover i {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*}*/

/*.ps-block--instagram:hover:before {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*}*/

.ps-block--comment {
    margin-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-block--comment h4 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.ps-block--comment h4 span {
    padding-left: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #555;
}

.ps-block--comment .ps-block__thumbnail {
    max-width: 45px;
}

.ps-block--comment .ps-block__thumbnail img {
    border-radius: 50%;
}

.ps-block--comment .ps-block__content {
    padding-left: 20px;
}

.ps-block--your-order {
    padding: 50px;
    background-color: #f7f3ee;
}

.ps-block--your-order .ps-block__header h3 {
    margin-bottom: 0;
}

.ps-block--your-order .ps-block__divider {
    display: block;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.ps-block--your-order h5 {
    margin-bottom: 0;
    font-size: 20px;
    color: #555;
}

.ps-block--your-order p {
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 600;
}

.ps-block--your-order p span, .ps-block--your-order p strong {
    float: right;
    font-size: 24px;
    font-weight: 700;
}

.ps-block--your-order p.total {
    font-size: 20px;
}

.ps-block__detail p.total strong {
    color: #ce873a;
    font-size: 30px;
}

.ps-block--your-order .ps-block__payment-methond {
    padding-top: 40px;
}

.ps-block--your-order .ps-block__payment-methond .ps-radio {
    margin-bottom: 26px;
}

.ps-block--your-order .ps-block__payment-methond .ps-radio > label {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    line-height: 20px;
}

.ps-block--your-order .ps-block__payment-methond .ps-radio i.fa {
    margin-right: 10px;
    color: #636363;
}

.ps-block--your-order .ps-block__payment-methond .ps-radio i.fa:first-child {
    margin-left: 20px;
}

@media (max-width: 479px) {
    .ps-block--your-order {
        padding: 50px 15px;
    }
}


.ps-block--review {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-block--review .br-wrapper a {
    font-size: 14px;
}

.ps-block--review .br-wrapper .br-widget a.br-selected:after {
    color: #f7941d;
}

.ps-block--review figure figcaption {
    margin-bottom: 10px;
    font-size: 19px;
}

.ps-block--review figure figcaption strong {
    color: #ce873a;
    font-weight: 600;
}

.ps-block--review figure figcaption span {
    padding-left: 30px;
    color: #a1a1a1;
}

.ps-block--review .ps-block__thumbnail {
    max-width: 70px;
}

.ps-block--review .ps-block__content {
    padding-left: 30px;
}

.ps-block--review .ps-block__content p {
    line-height: 1.6em;
}

.ps-panel--sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 400px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    background-color: #fff;
    -webkit-transform: translateX(+100%);
    -moz-transform: translateX(+100%);
    -ms-transform: translateX(+100%);
    -o-transform: translateX(+100%);
    transform: translateX(+100%);
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -o-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-panel--sidebar .ps-panel__header {
    position: relative;
    text-align: center;
    padding: 15px 20px;
    background-color: #ce873a;
}

.ps-panel--sidebar .ps-panel__header h3 {
    margin-bottom: 0;
    font-weight: 600;
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: .05em;
}

.ps-panel--sidebar .ps-panel__header .ps-btn--close {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
}

.ps-panel--sidebar .ps-panel__header .ps-btn--close:before, .ps-panel--sidebar .ps-panel__header .ps-btn--close:after {
    background-color: #ffffff;
    height: 60%;
}

.ps-panel--sidebar .ps-panel__content {
    padding-top: 10px;
    padding-bottom: 70px;
}

.ps-panel--sidebar.active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

@media (max-width: 479px) {
    .ps-panel--sidebar {
        width: 100%;
        max-width: 100%;
    }
}

.widget {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.widget .widget-title {
    margin-bottom: 2.5rem;
    font-weight: 700;
}

.widget:last-child {
    border-bottom: none;
}

.widget_footer .widget-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.4em;
}

.widget_footer p {
    margin-bottom: 0;
    color: #707070;
}

.widget_footer p span {
    color: #ce873a;
}

.widget_footer .ps-list--payment-method li {
    display: inline-block;
    margin-right: 10px;
}

.widget_footer .ps-list--payment-method li a {
    color: #c2c2c2;
    font-size: 20px;
}

.widget_footer .ps-list--payment-method li a:hover {
    color: #ce873a;
}

.widget_footer .ps-list--payment-method li:last-child {
    margin-right: 0;
}

.widget_shop {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed #ce873a;
}

.widget_shop .widget-title {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
}

.widget_categories ul li {
    margin-bottom: 15px;
}

.widget_categories ul li a {
    display: block;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #555;
}

.widget_categories ul li a:hover {
    color: #ce873a;
}

.widget_blog-categories .ps-list--arrow li a {
    text-transform: uppercase;
}

.widget_shop-ingredients .ps-checkbox {
    margin-bottom: 20px;
}

.widget_shop-ingredients .ps-checkbox:last-child {
    margin-bottom: 0;
}

.widget_shop-filter .ps-slider {
    display: block;
    margin-bottom: 10px;
    margin-left: 10px;
    height: 5px;
    background-color: #cecece;
    position: relative;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
}

.widget_shop-filter .ui-slider-range {
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ce873a;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
}

.widget_shop-filter .ui-slider-handle {
    display: inline-block;
    top: 50%;
    height: 24px;
    width: 24px;
    outline: none;
    cursor: pointer;
    background-color: #7f462c;
    border: none;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.widget_shop-filter .ps-slider__meta {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 1.8em;
    color: #555555;
    text-align: left;
}

.widget_shop-filter .ps-slider__meta span {
    margin-left: 5px;
    margin-right: 5px;
}

.widget_shop-filter .ps-filter__btn {
    background-color: #888888;
    color: #fff;
}

.widget_shop-filter .ps-filter__btn:hover {
    background-color: #222222;
}

.widget_blog {
    margin-bottom: 50px;
}

.widget_blog .widget-title {
    font-size: 20px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .widget_blog {
        margin-bottom: 30px;
    }
}

.widget_recent-posts .ps-post--sidebar {
    margin-bottom: 30px;
}

.widget_recent-posts .ps-post--sidebar:last-child {
    margin-bottom: 0;
}

.widget_tags a {
    display: inline-block;
    font-size: 14px;
    color: #555;
    text-transform: uppercase;
}

.widget_tags a:after {
    content: ',';
    margin-right: 4px;
}

.widget_tags a:hover {
    color: #ce873a;
}

.owl-carousel {
    position: relative;
}

.owl-carousel .owl-item img {
    width: auto;
}


.owl-carousel .ps-product {
    margin-bottom: 10px;
}

.ps-carousel--nav {
    position: relative;
    z-index: 10;
}

.ps-carousel--nav .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 20;
    width: 100%;
    height: 0;
}

.ps-carousel--nav .owl-nav > * {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ps-carousel--nav .owl-nav > * i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #000;
}

.ps-carousel--nav .owl-nav > *:hover {
    background-color: #ce873a;
}

.ps-carousel--nav .owl-nav > *:hover i {
    color: #ffffff;
}

.ps-carousel--nav .owl-nav .owl-prev {
    margin-left: 70px;
}

.ps-carousel--nav .owl-nav .owl-prev i {
    padding-right: 2px;
}

.ps-carousel--nav .owl-nav .owl-next {
    float: right;
    margin-right: 70px;
}

.ps-carousel--nav .owl-nav .owl-next i {
    padding-left: 2px;
}

.ps-carousel--nav .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none !important;
    text-align: center;
}

.ps-carousel--nav .owl-dots .owl-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.ps-carousel--nav .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.ps-carousel--nav .owl-dots .owl-dot.active {
    background-color: #000000;
}

.ps-carousel--nav.inside .owl-nav {
    z-index: 20;
    height: 0;
}

.ps-carousel--nav.inside .owl-nav > * {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ps-carousel--nav.inside .owl-prev {
    margin-left: 3rem;
}

.ps-carousel--nav.inside .owl-prev i {
    padding-right: 5px;
}

.ps-carousel--nav.inside .owl-next {
    float: right;
    margin-right: 3rem;
}

.ps-carousel--nav.inside .owl-next i {
    padding-left: 5px;
}

@media (max-width: 1199px) {
    .ps-carousel--nav {
        margin-bottom: 3rem;
        padding-bottom: 30px;
    }

    .ps-carousel--nav .owl-nav {
        display: none;
    }

    .ps-carousel--nav .owl-dots {
        display: block !important;
    }
}

.ps-carousel--animate .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
}

.ps-carousel--animate .slick-dots li {
    display: inline-block;
    vertical-align: top;
    line-height: 0;
    margin-right: 10px;
}

.ps-carousel--animate .slick-dots li button {
    display: inline-block;
    border: 0;
    padding: 0;
    font-size: 0;
    width: 12px;
    height: 12px;
    vertical-align: top;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
}

.ps-carousel--animate .slick-dots li.slick-active button {
    background-color: #000;
}

.ps-carousel--animate .slick-dots li:last-child {
    margin-right: 0;
}

@media (max-width: 479px) {
    .ps-carousel--animate .slick-dots {
        bottom: 5px;
    }

    .ps-carousel--animate .slick-dots li button {
        width: 8px;
        height: 8px;
    }
}

.ps-carousel--responsive {
    position: relative;
    z-index: 10;
}

.ps-carousel--responsive .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none !important;
    text-align: center;
}

.ps-carousel--responsive .owl-dots .owl-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
}

.ps-carousel--responsive .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.ps-carousel--responsive .owl-dots .owl-dot.active {
    background-color: #ce873a;
}

@media (max-width: 1199px) {
    .ps-carousel--responsive {
        margin-bottom: 3rem;
        padding-bottom: 30px;
    }

    .ps-carousel--responsive .owl-nav {
        display: none;
    }

    .ps-carousel--responsive .owl-dots {
        display: block !important;
    }
}

.ps-carousel--product-box {
    overflow: hidden;
}

.ps-carousel--product-box .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ps-carousel--product-box .owl-nav > * {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 40px;
}

.ps-carousel--product-box .owl-nav > * > i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ffffff;
}

.ps-carousel--product-box .owl-nav .owl-prev {
    padding-right: 2px;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.ps-carousel--product-box .owl-nav .owl-next {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.ps-carousel--product-box .owl-nav .owl-next i {
    padding-left: 2px;
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.ps-carousel--product-box .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
}

.ps-carousel--product-box .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
}

.ps-carousel--product-box .owl-dots .owl-dot:hover, .ps-carousel--product-box .owl-dots .owl-dot.active {
    background-color: #ffffff;
}

.ps-carousel--product-box .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.ps-carousel--product-box:hover .owl-nav .owl-prev {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.ps-carousel--product-box:hover .owl-nav .owl-next {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.ps-carousel--nav-inside {
    overflow: hidden;
}

.ps-carousel--nav-inside .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ps-carousel--nav-inside .owl-nav > * {
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ps-carousel--nav-inside .owl-nav > * > i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #000;
}

.ps-carousel--nav-inside .owl-nav .owl-prev {
    padding-right: 2px;
}

.ps-carousel--nav-inside .owl-nav .owl-next {
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

.ps-carousel--nav-inside .owl-nav .owl-next i {
    padding-left: 2px;
}

.ps-carousel--nav-inside .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    display: none;
    visibility: hidden;
    opacity: 0;
}

.ps-carousel--nav-inside .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
}

.ps-carousel--nav-inside .owl-dots .owl-dot:hover, .ps-carousel--nav-inside .owl-dots .owl-dot.active {
    background-color: #ffffff;
}

.ps-carousel--nav-inside .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.ps-carousel--nav-inside.second .owl-nav > * {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.3);
}

.ps-carousel--nav-inside.second .owl-nav > * i {
    font-size: 18px;
    color: #ffffff;
}

.ps-carousel--nav-inside.second .owl-nav > *:hover {
    background-color: #000;
}

.ps-carousel--nav-inside.second .owl-nav .owl-prev {
    margin-left: 20px;
}

.ps-carousel--nav-inside.second .owl-nav .owl-next {
    margin-right: 20px;
}

@media (max-width: 1199px) {
    .ps-carousel--nav-inside .owl-nav {
        display: none;
    }

    .ps-carousel--nav-inside .owl-dots {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.ps-carousel--dots .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 0;
}

.ps-carousel--dots .owl-nav > * {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background: transparent;
}

.ps-carousel--dots .owl-nav > *:before, .ps-carousel--dots .owl-nav > *:after {
    content: '';
    display: block;
    width: 50%;
    height: 1px;
    position: absolute;
    top: 50%;
    background-color: #535353;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.ps-carousel--dots .owl-nav > *:hover:before, .ps-carousel--dots .owl-nav > *:hover:after {
    background-color: #141414;
}

.ps-carousel--dots .owl-nav .owl-prev:before, .ps-carousel--dots .owl-nav .owl-prev:after {
    left: 50%;
}

.ps-carousel--dots .owl-nav .owl-prev:before {
    -webkit-transform: translate(-50%, -50%) rotate(-50deg);
    -moz-transform: translate(-50%, -50%) rotate(-50deg);
    -ms-transform: translate(-50%, -50%) rotate(-50deg);
    -o-transform: translate(-50%, -50%) rotate(-50deg);
    transform: translate(-50%, -50%) rotate(-50deg);
}

.ps-carousel--dots .owl-nav .owl-prev:after {
    -webkit-transform: translate(-50%, -50%) rotate(50deg);
    -moz-transform: translate(-50%, -50%) rotate(50deg);
    -ms-transform: translate(-50%, -50%) rotate(50deg);
    -o-transform: translate(-50%, -50%) rotate(50deg);
    transform: translate(-50%, -50%) rotate(50deg);
}

.ps-carousel--dots .owl-nav .owl-next:before, .ps-carousel--dots .owl-nav .owl-next:after {
    right: 0;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.ps-carousel--dots .owl-nav .owl-next:before {
    -webkit-transform: translate(-50%, -50%) rotate(-50deg);
    -moz-transform: translate(-50%, -50%) rotate(-50deg);
    -ms-transform: translate(-50%, -50%) rotate(-50deg);
    -o-transform: translate(-50%, -50%) rotate(-50deg);
    transform: translate(-50%, -50%) rotate(-50deg);
}

.ps-carousel--dots .owl-nav .owl-next:after {
    -webkit-transform: translate(-50%, -50%) rotate(50deg);
    -moz-transform: translate(-50%, -50%) rotate(50deg);
    -ms-transform: translate(-50%, -50%) rotate(50deg);
    -o-transform: translate(-50%, -50%) rotate(50deg);
    transform: translate(-50%, -50%) rotate(50deg);
}

@media (max-width: 1199px) {
    .ps-carousel--dots .owl-nav {
        padding: 0 30px;
    }
}

.ps-carousel--dots .owl-dots {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    text-align: center;
}

.ps-carousel--dots .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 20px;
    padding: 0;
    width: 5px !important;
    height: 5px !important;
    border-radius: 10px;
    border: none;
    background-color: #8a8a8a;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    vertical-align: text-bottom;
}

.ps-carousel--dots .owl-dots .owl-dot:hover, .ps-carousel--dots .owl-dots .owl-dot.active {
    width: 3px !important;
    height: 30px !important;
    background-color: #000000;
}

.ps-carousel--dots .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .ps-carousel--dots .owl-dots {
        bottom: 10px;
    }
}

@media (max-width: 1199px) {
    .ps-carousel--dots .owl-nav {
        display: none;
    }
}

.ps-carousel--dots-2 {
    padding-bottom: 50px;
}

.ps-carousel--dots-2 .owl-dots {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
}

.ps-carousel--dots-2 .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 20px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: none;
    background-color: #ce873a;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-carousel--dots-2 .owl-dots .owl-dot:hover, .ps-carousel--dots-2 .owl-dots .owl-dot.active {
    background-color: transparent;
    border: 2px solid #000;
}

.ps-carousel--dots-2 .owl-dots .owl-dot:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .ps-carousel--dots-2 .owl-dots {
        bottom: 10px;
    }
}

@media (max-width: 1199px) {
    .ps-carousel--dots-2 .owl-nav {
        display: none;
    }
}

.masonry-wrapper {
    margin: 0;
}

.masonry-wrapper .grid-item,
.masonry-wrapper .grid-sizer {
    width: 100%;
}

.masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper {
    background-color: #9EC3C3;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.setting {
    margin: 0;
    margin: -5px;
}

.setting .grid-item,
.setting .grid-sizer {
    width: 100%;
}

.setting .grid-item .grid-item__inner .grid-item__content-wrapper {
    background-color: #ccc;
}

.setting.ac_masonry-grid .grid-item__content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.setting.ac_masonry-grid .grid-item__inner {
    padding-top: 60%;
}

.setting.ac_masonry-grid .grid-item.extra-large .grid-item__inner, .setting.ac_masonry-grid .grid-item.wide .grid-item__inner {
    padding-top: 30%;
}

.setting.ac_masonry-grid .grid-item.high .grid-item__inner {
    padding-top: 120%;
}

.setting .grid-item .grid-item__content-wrapper {
    margin: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

.setting .grid-item,
.setting .grid-sizer {
    width: 100%;
}

@media (min-width: 768px) {
    .setting .grid-item,
    .setting .grid-sizer {
        width: 50%;
    }

    .setting .grid-item.wide, .setting .grid-item.large {
        width: 100%;
    }

    .setting .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .setting .grid-item,
    .setting .grid-sizer {
        width: 33.33333%;
    }

    .setting .grid-item.wide, .setting .grid-item.large {
        width: 66.66667%;
    }

    .setting .grid-item.extra-large {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .setting .grid-item,
    .setting .grid-sizer {
        width: 33.33333%;
    }

    .setting .grid-item.wide, .setting .grid-item.large {
        width: 66.66667%;
    }

    .setting .grid-item.extra-large {
        width: 100%;
    }
}

.ps-product__thumbnail {
    mix-blend-mode: multiply;
    position: relative;
    z-index: 10;
    overflow: hidden;
    aspect-ratio: 1;
}

.ps-product__thumbnail img {
    max-width: 100%;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-product__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.ps-product .ps-badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 55px;
    height: 55px;
}

.ps-product .ps-badge i {
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
}

.ps-product .ps-badge--sale {
    background-image: url("/img/badge/sale.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product .ps-badge--new {
    background-image: url("/img/badge/new.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product .ps-product__title {
    /* font-family: ModamWeb, serif; */
    font-weight: 700;
    color: #000;
    font-size: 22px;
}

.ps-product .ps-product__title:hover {
    color: #ce873a;
}

.ps-product .ps-product__price {
    direction: rtl;
    display: block;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 600;
    color: #ce873a;
    line-height: 1.4em;
    text-transform: uppercase;
}

.ps-product .ps-product__price sub {
    font-size: 16px;
}

.ps-product .ps-product__price.sale del {
    color: #555;
    font-size: 20px;
}

.ps-product .ps-product__thumbnail .ps-badge {
    position: absolute;
    top: 0;
    left: 0;
}

.ps-product .ps-product__content {
    position: relative;
    text-align: center;
    padding: 0px 16px 30px;
}

.ps-product .ps-product__content p {
    direction: rtl;
    margin-bottom: 0;
    font-weight: 600;
}

.ps-product .ps-product__content p span {
    margin-right: 16px;
    color: #555;
}

@media (min-width: 1025px) {
    .ps-product .ps-product__content .ps-product__desc {
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }
}


.ps-product .ps-product__shopping {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 220px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
}

.ps-product .ps-product__shopping .ps-btn {
    display: inline-block;
    padding: 10px 25px;
    border-radius: 40px;
    color: #ffffff;
    font-size: 13px;
    background-color: #7f462c;
    text-transform: uppercase;
}

.ps-product .ps-product__shopping .ps-btn:hover {
    background-color: #ce873a;
}

.ps-product .ps-product__shopping .ps-product__actions {
    padding-left: 0px;
}

.ps-product .ps-product__shopping .ps-product__actions a {
    display: inline-block;
    margin-right: 0px;
    height: 40px;
    width: 40px;
    color: #111;
    border-radius: 40px;
    font-weight: 400;
    vertical-align: top;
    background-color: transparent;
}

.ps-product .ps-product__shopping .ps-product__actions a i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-product .ps-product__shopping .ps-product__actions a:hover {
    background-color: #7f462c;
}

.ps-product .ps-product__shopping .ps-product__actions a:hover i {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
    color: #ffffff;
}

.ps-product .ps-product__shopping .ps-product__actions a:last-child {
    margin-right: 0;
}

.ps-product:hover .ps-product__thumbnail img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    -moz-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -o-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}
@media (min-width: 1025px) {
    .ps-product:hover .ps-product__content .ps-product__desc {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
        -o-transform: translateY(50%);
        transform: translateY(50%);
    }
    .ps-product:hover .ps-product__content .ps-product__shopping {
        -webkit-transition-delay: 0.15s;
        -moz-transition-delay: 0.15s;
        -o-transition-delay: 0.15s;
        transition-delay: 0.15s;
        visibility: visible;
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}




@media (max-width: 767px) {
    .ps-product .ps-product__thumbnail {
        text-align: center;
    }

    .ps-product .ps-product__thumbnail img {
        max-height: 250px;
    }
}

@media (max-width: 479px) {
    .ps-product {
        max-width: 320px;
        margin: 0 auto;
    }

    .ps-product .ps-badge {
        width: 36px;
        height: 36px;
    }

    .ps-product .ps-badge i {
        font-size: 10px;
    }

    .ps-product .ps-product__thumbnail {
        text-align: center;
    }

    .ps-product .ps-product__thumbnail img {
        max-height: 200px;
    }

    .ps-product .ps-product__title {
        font-size: 16px;
    }

    .ps-product .ps-product__content {
        padding: 0 0 20px;
    }

    .ps-product .ps-product__content p {
        font-size: 14px;
    }

    .ps-product .ps-product__content p span {
        margin-right: 4px;
    }
}

.ps-product--horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    flex-direction: row-reverse;
}

.ps-product--horizontal .ps-product__title {
    font-size: 22px;
    font-weight: 700;
}

.ps-product--horizontal .ps-product__title:hover {
    color: #ce873a;
}

.ps-product--horizontal .ps-product__thumbnail {
    max-width: 70px;
}

.ps-product--horizontal .ps-product__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    flex-direction: row-reverse;

}

.ps-product--horizontal .ps-product__price {
    max-width: 80px;
    padding-left: 5px;
    text-align: right;
}

.ps-product--horizontal .ps-product__price span {
    color: #ce873a;
    font-size: 24px;
    font-weight: 600;
}

.ps-product--horizontal .ps-product__content {
    padding-right: 6px;
}

.ps-product--horizontal .ps-product__content p {
    margin-bottom: 0;
    color: #c2c2c2;
    direction: rtl;
}

.ps-product--horizontal-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-product--horizontal-2 .ps-product__thumbnail {
    max-width: 270px;
}

.ps-product--horizontal-2 .ps-product__title {
    font-size: 30px;
    font-weight: 700;
    color: #000;
}

.ps-product--horizontal-2 .ps-product__title:hover {
    color: #ce873a;
}

.ps-product--horizontal-2 .ps-product__content {
    text-align: left;
}

.ps-product--horizontal-2 .ps-product__content .ps-product__meta {
    margin-bottom: 16px;
    font-size: 16px;
}

.ps-product--horizontal-2 .ps-product__content p {
    margin-bottom: 10px;
    font-size: 19px;
    line-height: 1.4em;
    color: #555;
}

.ps-product--horizontal-2 .ps-product__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 30px;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__price {
    margin-right: 10px;
    margin-bottom: 0;
}

.ps-product--horizontal-2 .ps-product__footer .ps-btn {
    display: inline-block;
    padding: 10px 25px;
    border-radius: 40px;
    color: #ffffff;
    font-size: 13px;
    background-color: #7f462c;
    text-transform: uppercase;
}

.ps-product--horizontal-2 .ps-product__footer .ps-btn:hover {
    background-color: #ce873a;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions {
    padding-left: 20px;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions a {
    display: inline-block;
    margin-right: 10px;
    height: 40px;
    width: 40px;
    color: #111;
    border-radius: 40px;
    font-weight: 400;
    vertical-align: top;
    background-color: transparent;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions a i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions a:hover {
    background-color: #7f462c;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions a:hover i {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
    color: #ffffff;
}

.ps-product--horizontal-2 .ps-product__footer .ps-product__actions a:last-child {
    margin-right: 0;
}

.ps-product--horizontal-2:hover .ps-product__content .ps-product__desc {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

@media (max-width: 767px) {
    .ps-product--horizontal-2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-product--horizontal-2 .ps-product__thumbnail {
        max-width: 100%;
        text-align: left;
    }

    .ps-product--horizontal-2 .ps-product__content {
        max-width: 100%;
    }

    .ps-product--horizontal-2 .ps-product__content p {
        font-size: 16px;
    }
}

@media (max-width: 479px) {
    .ps-product--horizontal-2 .ps-product__footer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-product--horizontal-2 .ps-product__footer .ps-product__price {
        width: 100%;
        margin-bottom: 10px;
    }
}

.ps-product--sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ps-product--sidebar .ps-product__title {
    display: block;
    margin-bottom: 10px;
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.ps-product--sidebar .ps-product__thumbnail {
    max-width: 100px;
}

.ps-product--sidebar .ps-product__price {
    font-size: 18px;
    font-weight: 600;
    color: #ce873a;
}

.ps-product--sidebar .ps-product__content {
    padding-left: 20px;
}

.ps-product--sidebar .ps-product__content p {
    margin-bottom: 0;
    font-size: 14px;
    color: #555;
}

@media (max-width: 479px) {
    .ps-product--sidebar .ps-product__thumbnail {
        margin-bottom: 30px;
        max-width: 60px;
    }
}

.ps-product--combo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
}

.ps-product--combo .ps-badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 144px;
    height: 144px;
}

.ps-product--combo .ps-badge i {
    font-size: 48px;
    color: #ffffff;
    font-style: normal;
}

.ps-product--combo .ps-badge--sale {
    background-image: url("/img/bg/badge.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--combo .ps-badge--new {
    background-image: url("/img/bg/badge-new.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--combo figure {
    margin-bottom: 45px;
}

.ps-product--combo figure figcaption {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
}

.ps-product--combo figure P {
    font-weight: 600;
    color: #555555;
}

.ps-product--combo .ps-product__title {
    margin-bottom: 30px;
    font-size: 36px;
    color: #000;
}

.ps-product--combo .ps-product__price {
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
}

.ps-product--combo .ps-product__price span {
    padding-left: 20px;
    color: #ce873a;
    font-size: 48px;
}

.ps-product--combo .ps-product__price del {
    font-size: 24px;
    color: #898989;
    margin-right: 10px;
}

.ps-product--combo .ps-product__book {
    padding-top: 20px;
}

.ps-product--combo .ps-product__thumbnail {
    max-width: 50%;
}

.ps-product--combo .ps-product__thumbnail .ps-badge {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media (max-width: 1199px) {
    .ps-product--combo .ps-product__thumbnail .ps-badge {
        bottom: auto;
        top: 0;
        width: 46px;
        height: 46px;
    }

    .ps-product--combo .ps-product__thumbnail .ps-badge i {
        font-size: 18px;
    }
}

@media (max-width: 991px) {
    .ps-product--combo {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-product--combo .ps-product__thumbnail {
        max-width: 100%;
        margin-bottom: 30px;
    }
}

.ps-product--cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ps-product--cart .ps-product__thumbnail {
    max-width: 100px;
}

.ps-product--cart .ps-product__content .ps-product__title {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    min-width: 100px;
    display: inline-block;
}

.ps-product--cart .ps-product__content .ps-product__title:hover {
    color: #ce873a;
}

.ps-product--mini-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row-reverse!important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-product--mini-cart .ps-product__thumbnail {
    max-width: 80px;
}

.ps-product--mini-cart .ps-product__title {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.ps-product--mini-cart .ps-product__title:hover {
    color: #ce873a;
}

.ps-product--mini-cart .ps-product__content {
    text-align: right;
    position: relative;
    padding-right: 40px;
    padding-left: 20px;
}

.ps-product--mini-cart .ps-product__content .ps-btn--close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.ps-product--mini-cart .ps-product__content .ps-btn--close:before, .ps-product--mini-cart .ps-product__content .ps-btn--close:after {
    width: 1px;
    background-color: #000000;
}

.ps-product--mini-cart .ps-product__content .ps-btn--close:hover:before, .ps-product--mini-cart .ps-product__content .ps-btn--close:hover:after {
    background-color: #FF3C35;
}

.ps-product--mini-cart .ps-product__content p {
    margin-bottom: 0;
    font-size: 16px;
    color: #555;
}

.ps-product--mini-cart .ps-product__content small {
    color: #ce873a;
    font-size: 20px;
    font-weight: 600;
}

.ps-product--detail {
    /*margin-bottom: 5rem;*/
}

.ps-product--detail h1 {
    margin-bottom: 10px;
    font-size: 48px;
    color: #000;
    font-weight: 700;
    line-height: 1.2em;
}

.ps-product--detail .slick-slide {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

.ps-product--detail .slick-slide:focus, .ps-product--detail .slick-slide:active, .ps-product--detail .slick-slide:hover {
    outline: none;
}

.ps-product--detail .ps-badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 55px;
    height: 55px;
}

.ps-product--detail .ps-badge i {
    font-size: 18px;
    color: #ffffff;
    font-style: normal;
}

.ps-product--detail .ps-badge--sale {
    background-image: url("/img/badge/sale.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--detail .ps-badge--new {
    background-image: url("/img/badge/new.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--detail .ps-product__variants .item {
    margin-bottom: 10px;
    cursor: pointer;
}

.ps-product--detail .ps-product__variants .item img {
    opacity: .5;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.ps-product--detail .ps-product__variants .item .ps-video {
    position: relative;
    z-index: 10;
}

.ps-product--detail .ps-product__variants .item .ps-video:before {
    content: "\f04b";
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    z-index: 20;
}

.ps-product--detail .ps-product__variants .item:last-child {
    margin-bottom: 0;
}

.ps-product--detail .ps-product__variants .item.slick-current img {
    opacity: 1;
}

.ps-product--detail .ps-product__variants .item:hover img {
    width: 100%;
    opacity: 1;
}

.ps-product--detail .ps-product__gallery {
    position: relative;
}

.ps-product--detail .ps-product__gallery .slick-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100;
    width: 35px;
    height: 35px;
    color: #000;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
}

.ps-product--detail .ps-product__gallery .slick-arrow i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ps-product--detail .ps-product__gallery .slick-arrow:first-child {
    left: 20px;
}

.ps-product--detail .ps-product__gallery .slick-arrow:last-child {
    right: 10px;
}

.ps-product--detail .ps-product__gallery .slick-arrow:hover {
    background-color: #ce873a;
    color: #fff;
}

.ps-product--detail .ps-product__gallery:hover .item:before {
    visibility: visible;
    opacity: 1;
}

.ps-product--detail .ps-product__gallery:hover .slick-arrow {
    visibility: visible;
    opacity: 1;
}

.ps-product--detail .ps-product__video a {
    vertical-align: middle;
}

.ps-product--detail .ps-product__video a:hover.ps-btn--black {
    background-color: #ce873a;
    border-color: #ce873a;
}

.ps-product--detail .ps-product__video i {
    font-size: 20px;
    font-weight: bold;
    vertical-align: middle;
}

.ps-product--detail .ps-product__info {
    text-align: left;
}

.ps-product--detail .ps-product__meta p {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    padding-right: 10px;
    line-height: 20px;
}

.ps-product--detail .ps-product__meta p a {
    color: #06c;
}

.ps-product--detail .ps-product__meta p:after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 14px;
    background-color: #cccccc;
}

.ps-product--detail .ps-product__meta .ps-product__rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-product--detail .ps-product__meta .ps-product__rating .br-wrapper a {
    font-size: 14px;
}

.ps-product--detail .ps-product__meta .ps-product__rating .br-wrapper .br-widget a.br-selected:after {
    color: #F7941D;
}

.ps-product--detail .ps-product__meta .ps-product__rating span {
    margin-left: 10px;
    color: #555;
}

.ps-product--detail .ps-product__price {
    direction: rtl;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    color: #ce873a;
}

.ps-product--detail .ps-product__price sub {
    font-size: 16px;
}

.ps-product--detail .ps-product__price.sale del {
    margin-right: 0;
    font-size: 18px;
    font-weight: 500;
}

.ps-product--detail .ps-product__price.sale small {
    font-size: 18px;
    font-weight: 500;
}

.ps-product--detail .ps-product__price del {
    font-style: normal;
    color: #bcbcbc;
    font-size: 18px;
}

.ps-product--detail .ps-product__desc {
    direction: rtl;
    text-align: right;
    margin-bottom: 20px;

}

.ps-product--detail .ps-product__desc p {
    margin-bottom: 20px;
    color: #555;
    font-weight: 600;
    font-size: 19px;
}

.ps-product--detail .ps-product__desc p a {
    color: #ce873a;
}

.ps-product--detail .ps-product__desc p strong {
    font-weight: 700;
}

.ps-product--detail .ps-product__specification {
    text-align: right;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #f5e7d8;
}

.ps-product--detail .ps-product__specification p {
    color: #555;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}

.ps-product--detail .ps-product__specification p strong {
    font-weight: 700;
    margin-right: 20px;
    color: #000;
    text-transform: uppercase;
}

.ps-product--detail .ps-product__specification p a {
    color: #ce873a;
    font-size: 18px;
}

.ps-product--detail .ps-product__specification p a:hover {
    color: #000;
    text-decoration: underline;
}

.ps-product--detail .ps-product__shopping {
    justify-content: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.ps-product--detail .ps-product__shopping > * {
    margin-right: 20px;
}

.ps-product--detail .ps-product__shopping .select2 {
    max-width: 200px;
    min-width: 200px;
}

.ps-product--detail .ps-product__shopping .select2 .select2-selection--single {
    border-radius: 50px;
    border-color: #d7d7d7;
}

.ps-product--detail .ps-product__shopping .select2 .select2-selection--single .select2-selection__rendered {
    line-height: 20px;
    padding: 12px 24px 10px;
    font-size: 16px;
    color: #555;
    font-weight: 600;
}

.ps-product--detail .ps-product__shopping .select2 .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    height: 20px;
}

.ps-product--detail .ps-product__shopping .select2 .select2-selection--single .select2-selection__arrow:before {
    content: "\f0d7";
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ce873a;
}

.ps-product--detail .ps-product__shopping .select2 .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.ps-product--detail .ps-product__shopping .form-group--number {
    display: inline-block;
    max-width: 120px;
    vertical-align: top;
}

.ps-product--detail .ps-product__shopping .form-group--number input {
    height: 45px;
    border-radius: 50px;
    border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__shopping .form-group--number i {
    color: rgba(0, 0, 0, 0.25);
}

.ps-product--detail .ps-product__shopping .ps-btn {
    margin-right: 0;
    padding: 13px 30px 12px;
    font-weight: 600;
    vertical-align: top;
    background-color: #7f462c;
}

.ps-product--detail .ps-product__shopping .ps-btn:hover {
    background-color: #ce873a;
}

.ps-product--detail .ps-product__sharing p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    line-height: 40px;
}

.ps-product--detail .ps-product__sharing p a {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    color: #707070;
}

.ps-product--detail .ps-product__sharing p a:first-child {
    margin-left: 30px;
}

.ps-product--detail .ps-product__sharing .ps-product__actions {
    max-width: 80px;
    display: inline-block;
    margin-right: 100px;
}

.ps-product--detail .ps-product__sharing .ps-product__actions > a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #000;
    font-size: 20px;
    vertical-align: top;
    border-radius: 50px;
}

.ps-product--detail .ps-product__sharing .ps-product__actions > a:hover {
    background-color: #ce873a;
}

.ps-product--detail .ps-product__sharing .ps-product__actions > a:hover i {
    color: #ffffff;
    font-size: 14px;
}

.ps-product--detail .ps-product__sharing .ps-product__actions > a i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ps-product--detail .ps-product__thumbnail {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row-reverse nowrap;
    -moz-flex-flow: row-reverse nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    align-content: flex-start;
    -ms-flex-line-pack: start;
}

.ps-product--detail .ps-product__thumbnail > * {
    width: 100%;
}

.ps-product--detail .ps-product__thumbnail .ps-product__badge {
    width: auto;
}

.ps-product--detail .ps-product__thumbnail figure {
    position: relative;
    display: block;
}

.ps-product--detail .ps-product__thumbnail .ps-product__variants {
    max-width: 60px;
    width: 100%;
    min-width: 60px;
}

.ps-product--detail .ps-product__thumbnail .ps-product__video {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 20;
}

.ps-product--detail .ps-product__thumbnail .ps-wrapper {
    position: relative;
}

.ps-product--detail .ps-product__heading {
    margin-bottom: 40px;
    margin-top: 10px;
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] figure {
    max-width: 100%;
    padding-left: 0;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants {
    max-width: 100%;
    text-align: center;
    width: 100%;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .item {
    margin-bottom: 0;
    margin-right: 10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .item.slick-active:last-child {
    margin-right: 0;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-list {
    margin-right: -10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:first-child {
    left: -20px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:first-child i:before {
    content: "\f104";
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:last-child {
    right: 0px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:last-child i:before {
    content: "\f105";
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:hover i {
    color: #000;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__gallery {
    margin-bottom: 10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__gallery img {
    width: 100%;
}

.ps-product--detail .ps-product__header {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-product--detail .ps-product__header > * {
    width: 100%;
}

.ps-product--detail .ps-product__header .ps-product__thumbnail {
    max-width: 43%;
}

.ps-product--detail .ps-product__header .ps-product__info {
    max-width: 57%;
    padding-left: 30px;
}

.ps-product--detail > .ps-product__content {
    padding-top: 100px;
}

.ps-product--detail .ps-tab-list {
    position: relative;
    display: block;
    margin: 0 0 50px;
    padding-left: 0;
    background-color: #ffffff;
    border-bottom: 1px dashed #ebcfb0;
    text-align: center;
}

.ps-product--detail .ps-tab-list li {
    display: inline-block;
}

.ps-product--detail .ps-tab-list li a {
    position: relative;
    display: block;
    font-size: 16px;
    padding: 20px 20px;
    line-height: 20px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.ps-product--detail .ps-tab-list li a:after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 25px;
    border-right: 1px dashed #bfa295;
}

.ps-product--detail .ps-tab-list li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 26px;
    height: 26px;
    height: 26px;
    background-color: #fff;
    border-left: 1px dashed #ebcfb0;
    border-bottom: 1px dashed #ebcfb0;
    -webkit-transition: -webkit-transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    transition: -webkit-transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.75s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transform: translate(-50%, 50%) rotate(-45deg);
    -moz-transform: translate(-50%, 50%) rotate(-45deg);
    -ms-transform: translate(-50%, 50%) rotate(-45deg);
    -o-transform: translate(-50%, 50%) rotate(-45deg);
    transform: translate(-50%, 50%) rotate(-45deg);
    visibility: hidden;
    opacity: 0;
}

.ps-product--detail .ps-tab-list li a:hover {
    color: #ce873a;
}

.ps-product--detail .ps-tab-list li a:hover:before {
    visibility: visible;
    opacity: 1;
}

.ps-product--detail .ps-tab-list li:last-child {
    margin-right: 0;
}

.ps-product--detail .ps-tab-list li:last-child > a:after {
    display: none;
}

.ps-product--detail .ps-tab-list li.active a {
    color: #ce873a;
}

.ps-product--detail .ps-tab-list li.active a:before {
    visibility: visible;
    opacity: 1;
}

.ps-product--detail .ps-tab h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
}

.ps-product--detail .ps-tab p {
    margin-bottom: 30px;
    font-size: 19px;
}

.ps-product--detail .ps-product .ps-product__price {
    font-size: 16px;
    font-weight: 400;
}

.ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail {
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail figure {
    padding-left: 0;
    padding-right: 10px;
}

@media (max-width: 1199px) {
    .ps-product--detail .ps-product__thumbnail {
        -webkit-flex-flow: column wrap;
        -moz-flex-flow: column wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-product--detail .ps-product__thumbnail figure {
        margin-bottom: 30px;
        padding: 0;
        width: 100%;
        max-width: 100%;
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__variants {
        position: relative;
        padding: 0 40px;
        float: none;
        width: 100%;
        max-width: 100%;
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__variants .item {
        border: none;
        margin-right: 10px;
        margin-bottom: 0;
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow {
        display: inline-block;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 16px;
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow:first-child {
        left: 0;
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow:last-child {
        right: 0;
    }

    .ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail {
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail figure {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 991px) {
    .ps-product--detail .ps-product__header .ps-product__thumbnail {
        padding-right: 0;
    }

    .ps-product--detail .ps-product__header .ps-product__info {
        padding-left: 3rem;
    }

    .ps-product--detail .ps-product__shopping .form-group--number {
        max-width: 100px;
    }

    .ps-product--detail .ps-product__thumbnail {
        padding-right: 0;
    }

    .ps-product--detail > .ps-product__content {
        padding-top: 75px;
    }

    .ps-product--detail > .ps-product__content .tab-list li {
        margin-right: 3rem;
    }

    .ps-product--detail > .ps-product__content .tab-list li a {
        font-size: 1.4rem;
    }
}

@media (max-width: 767px) {
    .ps-product--detail .ps-product__desc {
        text-align: justify;

    }

    .ps-product--detail .ps-product__header {
        -webkit-flex-flow: column wrap;
        -moz-flex-flow: column wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-product--detail .ps-product__header .ps-product__thumbnail {
        max-width: 100%;
        margin-bottom: 3rem;
    }

    .ps-product--detail .ps-product__header .ps-product__info {
        width: 100%;
        padding-left: 0;
        max-width: 100%;
    }

    .ps-product--detail h1 {
        font-size: 2.4rem;
    }

    .ps-product--detail .ps-product__sharing {
        text-align: left;
    }

    .ps-product--detail > .ps-product__content {
        padding-top: 50px;
    }

    .ps-product--detail > .ps-product__content .ps-tab-list li {
        display: block;
        margin-right: 0;
    }

    .ps-product--detail > .ps-product__content .ps-tab-list li a {
        display: block;
        padding: 5px 0;
        font-size: 18px;
    }

    .ps-product--detail > .ps-product__content .ps-tab-list li a:before {
        display: none;
    }
}

@media (max-width: 479px) {
    .ps-product--detail .ps-product__meta p {
        float: none;
        margin-bottom: 1rem;
    }

    .ps-product--detail .ps-product__meta > a {
        display: block;
        float: none;
        padding-left: 0;
    }

    .ps-product--detail .ps-product__shopping {
        display: block;
    }

    .ps-product--detail .ps-product__shopping figure {
        width: 100%;
    }

    .ps-product--detail .ps-product__shopping figure .form-group--number {
        width: 100%;
        max-width: 100%;
    }

    .ps-product--detail .ps-product__shopping .select2 {
        margin-bottom: 10px;
        min-width: 100%;
    }

    .ps-product--detail .ps-product__shopping .form-group--number {
        margin-bottom: 10px;
        max-width: 100%;
        width: 100%;
    }

    .ps-product--detail .ps-product__shopping .ps-btn {
        width: 100%;
        margin-right: 0;
        text-align: center;
    }
}

@media (max-width: 375px) {
    .ps-product--detail .ps-product__shopping .ps-form--number {
        max-width: 100%;
        width: 100%;
        margin-bottom: 1rem;
    }

    .ps-product--detail .ps-product__shopping .ps-btn {
        padding-left: 20px;
        padding-right: 20px;
    }

    .ps-product--detail .ps-product__shopping button {
        width: 100%;
    }
}

.ps-product--sticky .ps-product__header .ps-product__thumbnail {
    max-width: 50%;
}

.ps-product--sticky .ps-product__header .ps-product__info {
    max-width: 50%;
}

.ps-product--background {
    padding-top: 0;
}

.ps-product--background .ps-product__header {
    padding-top: 220px;
    padding-bottom: 140px;
}

.ps-product--background .ps-product__header .ps-product__thumbnail {
    max-width: 50%;
}

.ps-product--background .ps-product__header .ps-product__info {
    max-width: 50%;
}

.ps-product--background .ps-product__content {
    padding-bottom: 100px;
}

.ps-product--background.center .ps-product__header {
    display: block;
}

.ps-product--background.center .ps-product__header .ps-product__thumbnail {
    margin: 0 auto;
    max-width: 500px;
}

.ps-product--background.center .ps-product__info {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
}

.ps-product--background.center .ps-product__info .ps-product__meta .ps-product__rating {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.ps-product--background.center .ps-product__info .ps-product__meta .ps-product__rating .br-wrapper {
    display: inline-block;
}

.ps-product--background.center .ps-product__shopping {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ps-product--background.center .ps-product__content {
    padding-top: 70px;
}

@media (max-width: 1199px) {
    .ps-product--background .ps-product__header .ps-product__thumbnail {
        max-width: 40%;
    }

    .ps-product--background .ps-product__header .ps-product__info {
        max-width: 60%;
    }
}

@media (max-width: 991px) {
    .ps-product--background .ps-product__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-product--background .ps-product__header .ps-product__thumbnail {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .ps-product--background .ps-product__header .ps-product__info {
        max-width: 100%;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .ps-product--background .ps-product__header {
        padding-top: 60px;
    }

    .ps-product--background .ps-product__content {
        padding-bottom: 60px;
    }
}

.ps-product--banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}

.ps-product--banner .ps-badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 150px;
    height: 150px;
}

.ps-product--banner .ps-badge i {
    color: #ffffff;
    font-style: normal;
    font-size: 48px;
}

.ps-product--banner .ps-badge--sale {
    background-image: url("/img/badge/sale-large.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--banner .ps-badge--new {
    background-image: url("/img/badge/new.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.ps-product--banner .ps-product__thumbnail {
    max-width: 50%;
    text-align: center;
}

.ps-product--banner .ps-product__content h4 {
    font-size: 16px;
    color: #ce873a;
    font-weight: 600;
    text-transform: uppercase;
}

.ps-product--banner .ps-product__content .ps-product__title {
    display: block;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2em;
    color: #000;
}

.ps-product--banner .ps-product__content p {
    margin-bottom: 30px;
    font-size: 24px;
}

.ps-product--banner.horizontal {
    display: block;
    max-width: 800px;
    margin: 0 auto;
}

.ps-product--banner.horizontal .ps-product__thumbnail {
    position: relative;
    padding-bottom: 20px;
    max-width: 100%;
    text-align: center;
}

.ps-product--banner.horizontal .ps-product__thumbnail a {
    display: inline-block;
    margin: 0 auto;
}

.ps-product--banner.horizontal .ps-product__thumbnail img {
    max-height: 400px;
    margin: 0 auto;
}

.ps-product--banner.horizontal .ps-product__thumbnail .ps-badge {
    position: absolute;
    top: 0;
    left: 0;
}

.ps-product--banner.horizontal .ps-product__content {
    max-width: 100%;
    text-align: center;
}

.ps-product--banner.horizontal .ps-product__content .ps-product__title {
    font-family: ModamWeb, serif;
    font-weight: 900;
    font-size: 36px;
}

.ps-product--banner.horizontal .ps-product__content p {
    text-transform: uppercase;
    color: #ce873a;
    font-size: 19px;
}

@media (max-width: 767px) {
    .ps-product--banner.horizontal {
        max-width: 500px;
        margin: 0 auto;
    }

    .ps-product--banner.horizontal .ps-badge {
        width: 100px;
        height: 100px;
    }

    .ps-product--banner.horizontal .ps-badge i {
        font-size: 30px;
    }

    .ps-product--banner.horizontal .ps-product__thumbnail img {
        max-height: 200px;
    }
}

@media (max-width: 479px) {
    .ps-product--banner.horizontal .ps-badge {
        width: 50px;
        height: 50px;
    }

    .ps-product--banner.horizontal .ps-badge i {
        font-size: 18px;
    }

    .ps-product--banner.horizontal .ps-product__thumbnail img {
        max-height: 160px;
    }

    .ps-product--banner.horizontal .ps-product__content .ps-product__title {
        margin-bottom: 10px;
        font-size: 24px;
    }

    .ps-product--banner.horizontal .ps-product__content p {
        font-size: 16px;
    }
}

.ps-product--banner.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ps-product--banner.reverse .ps-product__title {
    /*color: #ffffff;*/
}

.ps-product--banner.reverse p {
    /*color: #ffffff;*/
}

@media (max-width: 1199px) {
    .ps-product--banner .ps-product__thumbnail img {
        /*max-height: 250px;*/
    }

    .ps-product--banner .ps-product__content .ps-product__title {
        font-size: 36px;
    }
}

@media (max-width: 767px) {
    #homepage .ps-home-banner{
        display: block!important;
        padding-top: 50px;
        height: 80vh!important;
    }
    .ps-product--banner {
        display: block;
    }

    .ps-product--banner .ps-product__thumbnail {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .ps-product--banner .ps-product__thumbnail img {
        margin: 0 auto;
        /*max-height: 150px;*/
    }

    .ps-product--banner .ps-product__content {
        max-width: 100%;
        text-align: center;
    }

    .ps-product--banner .ps-product__content .ps-product__title {
        font-size: 24px;
    }

    .ps-product--banner .ps-product__content p {
        font-size: 16px;
    }
}


.ps-hero {
    position: relative;
    z-index: 11;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 480px;
    background-position: bottom !important;
    margin-bottom: 30px;
    mix-blend-mode: multiply;
    background-size: cover!important;
}

@media (max-width: 479px) {
    .ps-product--banner .ps-product__thumbnail img {
        margin: 0 auto;
        /*max-height: 100px;*/
    }

    .ps-hero {
        min-height: 210px;
    }
}

.ps-hero__heading {

    font-size: 48px;
    color: #000;
    margin-bottom: 0;
}

.ps-hero__container {
    padding-top: 55px;
    text-align: center;
}



@media (max-width: 991px) {
    .ps-hero__heading {
        font-size: 32px;
    }

    .ps-hero__container {
        position: relative;
        /* top: -50px; */
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    .ps-hero__container {
        padding-top: 100px;
    }
}

.ps-hero--blog {
    position: relative;
    z-index: 1;
    top: -40px;
    margin-bottom: -40px;
}

.ps-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
}

.ps-banner .ps-banner__content {
    max-width: 1170px;
}

.ps-banner--1 {
    position: relative;
    z-index: 10;
    text-align: center;
}

.ps-banner--1:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.ps-banner--1 h3 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    font-family: ModamWeb, serif;
}

.ps-banner--1 .ps-banner__content {
    position: relative;
    width: 100%;
    z-index: 10;
    text-align: center;
}

.ps-banner--1 .ps-banner__content img {
    display: inline-block;
    margin-bottom: 30px;
}

.ps-banner--1 .ps-banner__content h3 {
    margin-bottom: 20px;
}

.ps-banner--1 .ps-banner__content p {
    margin-bottom: 30px;
    color: #ffffff;
    font-size: 20px;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .ps-banner--1 .ps-banner__content img {
        margin-bottom: 20px;
        max-height: 80px;
    }

    .ps-banner--1 .ps-banner__content h3 {
        font-size: 40px;
    }

    .ps-banner--1 .ps-banner__content p {
        font-size: 16px;
    }
}

@media (max-width: 479px) {
    .ps-banner--1 .ps-banner__content h3 {
        font-size: 32px;
    }
}

@media (min-width: 1200px) {
    .table-responsive {
        overflow-x: initial;
    }
}

.ps-table thead > tr > th {
    padding: 30px 10px;
    font-size: 16px;
    font-weight: 700;
    color: #515356;
    text-transform: uppercase;
    border-bottom: 1px solid #ebebeb;
}

.ps-table thead > tr > th:first-child {
    padding-left: 0;
}

.ps-table tbody > tr > td {
    vertical-align: middle;
    padding: 20px 15px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    font-weight: 600;
    color: #555;
}

.ps-table tbody > tr > td:first-child {
    padding-left: 0;
}

.ps-table--whishlist tbody > tr > td:first-child {
    min-width: 350px;
}

.ps-table--whishlist .ps-table__actions {
    min-width: 300px;
    vertical-align: middle;
    text-align: right;
}

.ps-table--whishlist .ps-table__actions .ps-btn {
    padding: 10px 30px;
    margin-right: 10px;
    background-color: #7f462c;
}

.ps-table--whishlist .ps-table__actions .ps-btn:hover {
    background-color: #ce873a;
}

.ps-table--whishlist .ps-table__actions .ps-btn--close {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border: 1px solid #ececec;
}

.ps-table--whishlist .ps-table__actions .ps-btn--close:hover {
    background-color: #ce873a;
    border-color: #ce873a;
}

.ps-table--whishlist .ps-table__actions .ps-btn--close:hover:before, .ps-table--whishlist .ps-table__actions .ps-btn--close:hover:after {
    background-color: #ffffff;
}

.ps-table--shopping-cart .form-group--number {
    width: 120px;
}

.ps-table--shopping-cart .ps-btn--close {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border: 1px solid #ececec;
}

.ps-table--shopping-cart .ps-btn--close:before, .ps-table--shopping-cart .ps-btn--close:after {
    width: 1px;
    background-color: #2c2c2c;
}

.ps-table--shopping-cart .ps-btn--close:hover {
    background-color: #ce873a;
    border-color: #ce873a;
}

.ps-table--shopping-cart .ps-btn--close:hover:before, .ps-table--shopping-cart .ps-btn--close:hover:after {
    background-color: #ffffff;
}

.ps-table--shopping-cart .total {
    color: #ce873a;
}

.ps-table--shopping-cart tbody > tr > td:first-child {
    min-width: 300px;
}

@media (max-width: 767px) {

    .ps-table--shopping-cart tbody > tr > td:first-child {
        min-width: auto;
    }
}

.ps-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #fff;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.ps-loading__content {
    height: 100vh;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-loading__content > * {
    display: block;
    width: 25%;
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: -moz-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    transition: -webkit-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    transition: transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    transition: transform 1s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -o-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #fcfaf6;
}

.ps-loading.loaded {
    visibility: hidden;
    opacity: 0;
    background-color: transparent;
    -webkit-transition-delay: 0.7s;
    -moz-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s;
}

.ps-loading.loaded .ps-loading__content > * {
    -webkit-transform: scale3d(0, 1, 1);
    -moz-transform: scale3d(0, 1, 1);
    -ms-transform: scale3d(0, 1, 1);
    -o-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #ffffff;
    z-index: 999;
}

#loader-wrapper .loader-section.section-left {
    left: 0;
}

#loader-wrapper .loader-section.section-right {
    right: 0;
}

#loader-wrapper img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    z-index: 999999999;
}

.loaded #loader-wrapper img {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Loaded styles */
.loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    -o-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
}

.ps-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.ps-modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2rem 4rem;
    z-index: 10;
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    max-width: 945px;
    width: 100%;
}

.ps-modal__close {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background-color: #5e6b9d;
}

.ps-modal__close i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #ffffff;
}

.ps-modal.active {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 991px) {
    .ps-modal__container {
        max-width: 90%;
    }

    .ps-modal__close {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        background-color: transparent;
        width: 30px;
        height: 30px;
    }

    .ps-modal__close i {
        color: #5e6b9d;
    }
}

@media (max-width: 767px) {
    .ps-modal {
        overflow: auto;
    }

    .ps-modal__container {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        margin: 3rem auto;
        overflow: auto;
    }
}

@media (max-width: 479px) {
    .ps-modal__container {
        max-width: 300px;
    }
}

.ps-popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
}

.ps-popup__content {
    position: relative;
    max-width: 830px;
    width: 100%;
    padding: 67px 40px;
    -webkit-transition: tranform 0.4s ease;
    -moz-transition: tranform 0.4s ease;
    -o-transition: tranform 0.4s ease;
    transition: tranform 0.4s ease;
}

@media (min-width: 1200px) {
    .ps-popup__content {
        padding: 67px 80px;
    }
}

.ps-popup__close {
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.ps-popup__close:before, .ps-popup__close:after {
    background-color: #000;
}

.ps-popup__close:hover:before, .ps-popup__close:hover:after {
    background-color: #FF3C35;
}

.ps-popup.active {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

@media (max-width: 991px) {
    .ps-popup {
        padding: 100px 30px 20px;
    }

    .ps-popup .ps-popup__content {
        position: relative;
        top: 0;
        left: 0;
        max-width: 800px;
        margin: 0 auto;
    }

    .ps-popup.active .ps-popup__content {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media (max-width: 479px) {
    .ps-popup {
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .ps-popup .ps-popup__content {
        padding: 50px 20px;
    }
}

#menu-fullscreen .ps-modal__close {
    top: 60px;
    right: auto;
    left: 100px;
    background-color: #ae6d34;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

#menu-fullscreen .ps-modal__close:before, #menu-fullscreen .ps-modal__close:after {
    background-color: #fff;
}

#menu-fullscreen .ps-modal__container {
    max-width: 100%;
    height: 100vh;
}


.ps-accordion__header {

    position: relative;
    background-color: #f6f6f8;
    padding: 10px 40px 10px 20px;
    cursor: pointer;
}

.ps-accordion__header p {
    margin-bottom: 0;
}

.ps-accordion__header p:hover {

}

.ps-accordion__header span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 40px;
    height: 40px;
    background-color: #222;
}

.ps-accordion__header span i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ffffff;
}

.ps-accordion__content {
    padding: 20px;
}

.ps-tab {
    display: none;
}

.ps-tab.active {
    display: block;
}

/*3. COMPONENTS */
.ps-cart--mini {
    position: relative;
}

.ps-cart--mini .ps-cart__toggle {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
    color: #ffffff;
}

.ps-cart--mini .ps-cart__toggle span {
    position: absolute;
    bottom: 7px;
    right: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: top;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ce873a;
}

.ps-cart--mini .ps-cart__toggle span i {
    position: relative;
    top: -1px;
    display: inline-block;
    font-size: 10px;
    color: #ffffff;
    font-style: normal;
    line-height: 1em;
}
.header--mobile .header__actions a span{
    position: absolute;
    bottom: 4px;
    right: -4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: top;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #904d04;
}

.ps-cart--mini .ps-cart__toggle:hover {
    color: #ce873a;
}

.ps-cart--mini .ps-cart__items {
    position: relative;
    padding: 20px;
    background-color: #f7e8da;
    border: 1px solid #e1e1e1;
    border-bottom: none;
}

.ps-cart--mini .ps-cart__items:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -8px;
    right: 30px;
    width: 16px;
    height: 16px;
    border-left: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    background-color: #f7e8da;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.ps-cart--mini .ps-cart__items .ps-product--mini-cart {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #efd5ba;
}

.ps-cart--mini .ps-cart__items .ps-product--mini-cart:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.ps-cart--mini .ps-cart__content {
    position: absolute;
    min-width: 370px;
    right: -30px;
    z-index: 30;
    padding-top: 30px;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
}

.ps-cart--mini .ps-cart__footer {
    padding: 20px 30px 30px;
    background-color: #f7e8da;
    border: 1px solid #e1e1e1;
    border-top: 0;
}

.ps-cart--mini .ps-cart__footer h3 {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    line-height: 30px;
}

.ps-cart--mini .ps-cart__footer h3 strong {
    float: right;
    color: #ce873a;
    font-size: 30px;
}

.ps-cart--mini figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ps-cart--mini figure .ps-btn {
    margin-bottom: 10px;
    padding: 12px 30px;
    text-align: center;
}

.ps-cart--mini figure .ps-btn--dark {
    background-color: #7f462c;
}

.ps-cart--mini:hover .ps-cart__content {
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    transform: translate(0, 0px);
    visibility: visible;
    opacity: 1;
}

.ps-cart--mobile .ps-cart__content {
    padding: 20px 10px 0;
    min-height: calc(100vh - 250px);
}

.ps-cart--mobile .ps-cart__items {
    padding: 30px 0;
}

.ps-cart--mobile .ps-cart__items .ps-product--mini-cart {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #efd5ba;
}

.ps-cart--mobile .ps-cart__items .ps-product--mini-cart:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.ps-cart--mobile .ps-cart__footer {
    padding: 20px 30px 30px;
    background-color: #f7e8da;
    border: none;
}

.ps-cart--mobile .ps-cart__footer h3 {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    line-height: 30px;
}

.ps-cart--mobile .ps-cart__footer h3 strong {
    float: right;
    color: #ce873a;
    font-size: 30px;
}

.ps-cart--mobile figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ps-cart--mobile figure .ps-btn {
    margin-bottom: 10px;
    padding: 12px 30px;
    text-align: center;
}

.ps-cart--mobile figure .ps-btn--dark {
    background-color: #7f462c;
}

@media (max-width: 479px) {
    .ps-cart--mobile .ps-cart__footer {
        padding: 20px 15px 0;
    }

    .ps-cart--mobile .ps-cart__footer figure {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-cart--mobile .ps-cart__footer figure .ps-btn {
        width: 100%;
        text-align: center;
        max-width: 100%;
    }
}


.ps-form--book-your-table .form-control {
    border: none;
    padding: 0;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
}

.ps-form--book-your-table .form-control::-webkit-input-placeholder {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
}

.ps-form--book-your-table .form-control::-moz-placeholder {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
}

.ps-form--book-your-table .form-control:-moz-placeholder {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
}

.ps-form--book-your-table .form-control:-ms-input-placeholder {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
}

.ps-form--book-your-table .form-control:focus {
    background-color: transparent;
}

.ps-form--book-your-table .form-group {
    margin-bottom: 20px;
}

.ps-form--book-your-table .ps-form__header {
    text-align: center;
}

.ps-form--book-your-table .ps-form__header h4 {
    font-size: 16px;
    font-weight: 700;
    color: #ce873a;
    text-transform: uppercase;
}

.ps-form--book-your-table .ps-form__header h3 {
    margin-bottom: 16px;
    font-size: 36px;
    color: #ffffff;
}

.ps-form--book-your-table .ps-form__header i {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 48px;
}

.ps-form--book-your-table .ps-form__header p {
    font-size: 19px;
    color: #ffffff;
    max-width: 400px;
    margin: 0 auto 40px;
}

.ps-form--book-your-table .ps-form__header p span {
    color: #ce873a;
}

.ps-form--book-your-table .ps-form__footer {
    text-align: center;
    padding-top: 30px;
}

.ps-form--book-your-table .ps-form__footer button {
    padding: 0 45px;
    height: 50px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 50px;
    font-size: 15px;
    text-transform: uppercase;
}

.ps-form--book-your-table .ps-form__footer button:hover {
    background-color: #ab6d2a;
}

@media (max-width: 767px) {
    .ps-form--book-your-table .ps-form__header h3 {
        font-size: 26px;
    }
}





.ps-form--leave-a-comment h4 {
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
}

.ps-form--leave-a-comment .form-group {
    margin-bottom: 50px;
}

.ps-form--leave-a-comment .form-control {
    border: none;
    border-bottom: 1px dashed #ebcfb0;
    padding-left: 0;
}

@media (max-width: 767px) {
    .ps-form--leave-a-comment .form-group {
        margin-bottom: 30px;
    }
}

.ps-form--adminPanel {
    padding: 30px 20px;
}

.ps-form--adminPanel .form-control {
    border: none;
    height: 45px;
    border-radius: 50px;
    background-color: #f6f7fb;
    color: #000;
    font-weight: 600;
}

.ps-form--profile h4 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.ps-form--profile .form-control {
    border: none;
    height: 45px;
    border-radius: 50px;
    background-color: #f6f7fb;
    color: #000;
    font-weight: 700;
}

.ps-form--profile textarea.form-control {
    height: auto;
}

.ps-form--profile .form-group {
    margin-bottom: 30px;
}

.ps-form--profile .form-group label {
    text-align: right;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
}

.ps-form--profile .different-address {
    margin-bottom: 30px;
}

.ps-form--profile .different-address > label {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 20px;
}

.ps-form--profile .select2 .select2-selection--single {
    border: none;
}

.ps-form--profile .select2 .select2-selection--single .select2-selection__rendered {
    background-color: #f6f7fb;
    border-radius: 50px;
    font-weight: 700;
    color: #000;
    font-size: 16px;
    padding: 12px 20px 13px;
}

.ps-form--subscribe-popup {
    max-width: 320px;
    text-align: left;
}

.ps-form--subscribe-popup h5 {
    text-transform: uppercase;
    color: #ce873a;
    font-weight: 700;
}

.ps-form--subscribe-popup h3 {
    margin-bottom: 20px;
    font-family: ModamWeb, serif;
    font-size: 30px;
    color: #000000;
}

.ps-form--subscribe-popup h3 span {
    color: #ce873a;
    font-weight: 700;
}

.ps-form--subscribe-popup p {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
}

.ps-form--subscribe-popup input {
    border-radius: 50px;
    margin-bottom: 20px;
    border-color: #464646;
}

.ps-form--subscribe-popup button {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.ps-form--subscribe-popup .ps-checkbox {
    text-align: left;
}

.ps-form--subscribe-popup .ps-checkbox label {
    font-size: 18px;
    line-height: 20px;
}

@media (min-width: 1200px) {
    .ps-form--subscribe-popup .ps-checkbox {
        margin-top: 100px;
    }
}

@media (max-width: 479px) {
    .ps-form--subscribe-popup h3 {
        font-size: 24px;
    }

    .ps-form--subscribe-popup p {
        font-size: 16px;
    }
}

.ps-form--primary-search {
    position: relative;
}

.ps-form--primary-search input {
    height: 60px;
    border-radius: 50px;
    border: none;
    font-size: 20px;
    text-indent: 10px;
    background-color: #ffffff;
    color: #000;
}

.ps-form--primary-search input::-webkit-input-placeholder {
    font-size: 20px;
    color: #000;
}

.ps-form--primary-search input::-moz-placeholder {
    font-size: 20px;
    color: #000;
}

.ps-form--primary-search input:-moz-placeholder {
    font-size: 20px;
    color: #000;
}

.ps-form--primary-search input:-ms-input-placeholder {
    font-size: 20px;
    color: #000;
}

.ps-form--primary-search button {
    right: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
}

.ps-form--review {
    padding-top: 40px;
}

.ps-form--review .ps-form__header {
    text-align: center;
}

.ps-form--review .ps-form__header h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
}

.ps-form--review .form-group--inline label {
    color: #555555;
    font-size: 19px;
    line-height: 1.2em;
}

.ps-form--review .form-group {
    margin-bottom: 30px;
}

.ps-form--review .form-control {
    border: none;
    padding-left: 0;
    border-bottom: 1px dashed #ebcfb0;
}

.ps-form--review .br-wrapper a:after {
    font-size: 14px;
}

.ps-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transition-delay: 0;
    -moz-transition-delay: 0;
    -o-transition-delay: 0;
    transition-delay: 0;
}

.ps-search:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(206, 135, 58, 0.95);
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: -moz-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transform: scale3d(1, 0, 1);
    -moz-transform: scale3d(1, 0, 1);
    -ms-transform: scale3d(1, 0, 1);
    -o-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}

.ps-search__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 1200px;
    width: 100%;
    padding: 0 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ps-search .ps-form--primary-search {
    max-width: 800px;
    margin: 0 auto;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    visibility: hidden;
    opacity: 0;
}

.ps-search > .ps-btn--close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 50px;
    height: 50px;
    background-color: #000;
    z-index: 1000;
    -webkit-transform: scale(0, 0) rotate(0);
    -moz-transform: scale(0, 0) rotate(0);
    -ms-transform: scale(0, 0) rotate(0);
    -o-transform: scale(0, 0) rotate(0);
    transform: scale(0, 0) rotate(0);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    border: none;
}

.ps-search > .ps-btn--close:before, .ps-search > .ps-btn--close:after {
    background-color: #ffffff;
    height: 50%;
}

.ps-search > .ps-btn--close:hover {
    background-color: #f44336;
}

.ps-search.active {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

.ps-search.active:before {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}

.ps-search.active > .ps-btn--close {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1) rotate(180deg);
    -moz-transform: scale(1, 1) rotate(180deg);
    -ms-transform: scale(1, 1) rotate(180deg);
    -o-transform: scale(1, 1) rotate(180deg);
    transform: scale(1, 1) rotate(180deg);
    -webkit-transition-delay: 0.75s;
    -moz-transition-delay: 0.75s;
    -o-transition-delay: 0.75s;
    transition-delay: 0.75s;
}

.ps-search.active .ps-form--primary-search {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0.35s;
    -moz-transition-delay: 0.35s;
    -o-transition-delay: 0.35s;
    transition-delay: 0.35s;
    visibility: visible;
    opacity: 1;
}

@media (max-width: 479px) {
    .ps-search__content {
        padding: 0 1.5rem;
    }
}

.ps-section .ps-section__header {
    text-align: center;
    /*padding-bottom: 60px;*/
}

.ps-section .ps-section__header p {
    margin-bottom: 0;
    color: #ce873a;
    text-transform: uppercase;
}

.ps-section .ps-section__header h3 {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.ps-section .ps-section__header i {
    font-size: 36px;
}

@media (max-width: 767px) {
    .ps-section .ps-section__header {
        padding-bottom: 30px;
    }

    .ps-section .ps-section__header h3 {
        font-size: 30px;
    }

    .ps-section .ps-section__header i {
        font-size: 30px;
    }
}

.ps-testimonials {
    padding: 115px 0 120px;
    position: relative;
}

.ps-testimonials .ps-carousel--dots {
    position: relative;
    padding-bottom: 60px;
}

.ps-testimonials .ps-carousel--dots .owl-dots {
    bottom: 0;
}

.ps-testimonials .ps-carousel--dots .owl-dots .owl-dot.active {
    border-color: #000;
    background-color: #fff;
}

@media (max-width: 991px) {
    .ps-testimonials {
        padding: 80px 0;
    }
}

@media (max-width: 767px) {
    .ps-testimonials {
        padding: 60px 0;
    }
}

.ps-home-about {
    padding: 115px 0 110px;
}

.ps-home-about .ps-section__content {
    text-align: center;
}

.ps-home-about .ps-section__content h5 {
    font-size: 16px;
    color: #555;
}

.ps-home-about .ps-section__content h5 span {
    text-transform: uppercase;
    color: #ce873a;
}

.ps-home-about .ps-section__content p {
    margin-bottom: 30px;
    color: #555;
    font-size: 22px;
    line-height: 1.4em;
}

.ps-home-about .ps-section__content img {
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .ps-home-about {
        padding: 80px 0;
        text-align: center;
    }

    .ps-home-about .ps-section__image {
        padding-bottom: 40px;
    }

    .ps-home-about .ps-section__image img {
        max-height: 300px;
    }
}

@media (max-width: 767px) {
    .ps-home-about {
        padding: 60px 0;
    }

    .ps-home-about .ps-section__image img {
        max-height: 300px;
    }

    .ps-home-about .ps-section__content p {
        font-size: 16px;
    }
}

.ps-page--about .ps-home-about {
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    z-index: 1;
}

.ps-home-best-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-home-best-services > * {
    width: 100%;
    max-width: 50%;
}

.ps-home-best-services .ps-section__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-home-best-services .ps-section__right > * {
    max-width: 50%;
    width: 100%;
}

.ps-home-best-services .ps-section__container {
    padding: 100px 45px;
    background-color: #21202d;
}

.ps-home-best-services:nth-child(2n) .ps-section__container {
    background-color: #f3f3f3;
}

.ps-home-best-services:nth-child(2n-1) .ps-section__container .ps-section__header a img {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1)

}

.ps-home-best-services:nth-child(2n) .ps-section__container .ps-section__content p, .ps-home-best-services:nth-child(2n) .ps-section__container .ps-section__content li, .ps-home-best-services:nth-child(2n) .ps-section__container .ps-section__header h3 {
    color: #21202d;
}

.ps-home-best-services .ps-section__header {
    /*padding-bottom: 30px;*/
}

.ps-home-best-services .ps-section__header h3 {
    color: #ffffff;
}

.ps-home-best-services .ps-section__content {
    text-align: center;
}

.ps-home-best-services .ps-section__content p {
    margin-bottom: 26px;
    color: #ffffff;
    font-size: 19px;
    line-height: 1.4em;
    font-style: italic;
}

.ps-home-best-services .ps-section__content ul li {
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 1.6em;
}

@media (max-width: 1680px) {
    .ps-home-best-services .ps-section__left {
        max-width: 40%;
    }

    .ps-home-best-services .ps-section__right {
        max-width: 60%;
    }
}

@media (max-width: 1366px) {
    .ps-home-best-services .ps-section__left {
        max-width: 30%;
    }

    .ps-home-best-services .ps-section__right {
        max-width: 70%;
    }
}

@media (max-width: 1199px) {
    .ps-home-best-services .ps-section__left {
        max-width: 0%;
    }

    .ps-home-best-services .ps-section__right {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .ps-home-best-services .ps-section__right {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-home-best-services .ps-section__right > * {
        max-width: 100%;
    }

    .ps-home-best-services .ps-section__container {
        padding: 60px 30px;
    }

    .ps-home-best-services .ps-section__image {
        min-height: 300px;
    }
}

.ps-home-menu-of-day {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-home-menu-of-day .ps-section__header {
    padding-bottom: 30px;
}

.ps-home-menu-of-day .ps-section__header h3 {
    color: #ffffff;
}

.ps-home-menu-of-day .ps-section__left {
    padding: 75px 225px;
    background-color: #21202d;
}

.ps-home-menu-of-day .ps-section__right {
    max-width: 33.33333%;
}

@media (max-width: 1680px) {
    .ps-home-menu-of-day .ps-section__left {
        padding: 75px 100px;
    }
}

@media (max-width: 1199px) {
    .ps-home-menu-of-day .ps-section__left {
        max-width: 100%;
        padding: 75px 50px;
    }

    .ps-home-menu-of-day .ps-section__right {
        max-width: 0;
    }
}

@media (max-width: 479px) {
    .ps-home-menu-of-day .ps-section__left {
        padding: 50px 30px;
    }
}

.ps-home-blog {
    padding: 120px 0;
}

.ps-home-blog .ps-post {
    margin-bottom: 30px;
}

.ps-home-blog .ps-section__footer {
    padding-top: 20px;
    text-align: center;
}

.ps-home-blog .ps-section__footer .ps-btn--outline {
    padding-left: 50px;
    padding-right: 50px;
}

@media (max-width: 1199px) {
    .ps-home-blog {
        padding: 80px 0;
    }
}

@media (max-width: 991px) {
    .ps-home-blog {
        padding: 60px 0;
    }
}

@media (max-width: 479px) {
    .ps-home-blog {
        padding: 50px 0;
    }
}

.ps-home-awards {
    position: relative;
    z-index: 10;
}

.ps-home-awards:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
}

.ps-home-awards .ps-section__header {
    /*padding-bottom: 60px;*/
}

.ps-home-awards .ps-section__header h3 {
    color: #ffffff;
}

@media (max-width: 991px) {
    .ps-home-awards {
    }

    .ps-home-awards .ps-block--award {
        max-width: 500px;
        margin: 0 auto 40px;
    }
}

@media (max-width: 767px) {
    .ps-home-awards {
    }

    .ps-home-awards .ps-section__header {
        padding-bottom: 30px;
    }
}

@media (max-width: 479px) {
    .ps-home-awards {
    }
}


.ps-home-book-table.dark {
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    z-index: 10;
    padding: 90px 0;
}

.ps-home-book-table.dark:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
}

.ps-home-book-table.dark .ps-section__header {
    padding-top: 60px;
}

.ps-home-book-table.dark .ps-section__header h3 {
    color: #ffffff;
}

.ps-home-book-table.dark .ps-section__content {
    padding: 0 100px;
}

.ps-home-book-table.dark .ps-form--book-your-table {
    background-color: rgba(37, 37, 37, 0.8);
    padding: 60px 70px;
}

@media (max-width: 1199px) {
    .ps-home-book-table.dark {
        padding: 80px 0;
    }

    .ps-home-book-table.dark .ps-section__content {
        padding: 0 40px;
    }
}

@media (max-width: 991px) {
    .ps-home-book-table.dark {
        padding: 75px 0;
    }

    .ps-home-book-table.dark .ps-form--book-your-table {
        max-width: 550px;
        margin: 0 auto 30px;
        padding: 40px 30px;
    }
}

@media (max-width: 767px) {
    .ps-home-book-table.dark {
        padding: 60px 0;
    }

    .ps-home-book-table.dark .ps-form--book-your-table {
        padding: 40px 30px;
    }

    .ps-home-book-table.dark .ps-section__header {
        padding-top: 40px;
    }

    .ps-home-book-table.dark .ps-section__content {
        padding: 0 30px;
    }
}

@media (max-width: 479px) {
    .ps-home-book-table.dark {
        padding: 50px 0;
    }

    .ps-home-book-table.dark .ps-form--book-your-table {
        padding: 30px 20px;
    }
}

.ps-shopping {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 80px 0 120px;
}

.categories{
    margin-bottom: 30px;
    position: sticky;
    top: 69px;
    background-color: #f3f3f3;
    z-index: 31;
    padding-top: 10px;
}

.ps-shopping p {
    margin-bottom: 0;
    font-size: 19px;
    color: #555;
    line-height: 45px;
}
.ps-product__categories{
    padding: 0;
    text-align:center;
}
.ps-product__categories li {
    display: inline-block;
    vertical-align: top;
    margin: 8px 10px;
}


.ps-product__categories li a {
    display: block;
    font-size: 16px;
    color: #555555;
    user-select: none;
}

.ps-product__categories li a:hover {
    color: #ce873a;
}

.ps-product__categories li.active a {
    color: #ce873a;
}

.ps-shopping .ps-product-box .ps-product {
    margin-bottom: 40px;
}

.ps-shopping__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-shopping__top > * {
    /* max-width: 50%; */
}

.ps-shopping__top .ps-select {
    max-width: 100%;
}

.ps-shopping__top figure {
    text-align: right;
}

.ps-shopping__top figure > * {
    display: inline-block;
}

.ps-shopping__top figure .select2 {
    margin-right: 20px;
    max-width: 230px;
    min-width: 230px;
    vertical-align: top;
}

.ps-shopping__top figure .select2 .select2-selection--single {
    border: none;
}

.ps-shopping__top figure .select2 .select2-selection--single .select2-selection__rendered {
    padding: 12px 20px;
    text-align: left;
    font-size: 19px;
    color: #464646;
    border-radius: 50px;
    border: 1px solid #464646;
}

.ps-shopping__top figure a {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: 1px solid #464646;
    border-radius: 50%;
}

.ps-shopping__top figure a i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #acacac;
}

.ps-shopping__top figure a:last-child {
    margin-right: 0;
}

.ps-shopping__top figure a.active i {
    color: #000;
}

.ps-shopping .ps-shopping__left {
    max-width: 290px;
}

.ps-shopping .ps-shopping__right {
    padding-left: 80px;
}

.ps-shopping--no-sidebar {
    display: block;
}


.ps-shopping__top__center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}
.ps-shopping__top__center > div{
    justify-content: center;
}

.ps-shopping__top__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.ps-shopping__top__right p {
    margin-right: 20px;
}

.ps-shopping.ps-shopping--reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
}

.ps-shopping.ps-shopping--reverse .ps-shopping__right {
    padding-left: 0;
    padding-right: 80px;
}

@media (max-width: 1199px) {
    .ps-shopping__top {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-shopping__top > * {
        max-width: 100%;
    }

    .ps-shopping__top figure {
        text-align: left;
    }
}

@media (max-width: 1440px) and (min-width: 1280px) {
    .ps-shopping--fullwidth .ps-product-box .col-xl-3 {
        max-width: 33.3333%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
        flex: 0 0 33.333%;
    }
}

@media (max-width: 1199px) {
    .categories{
        margin-bottom: 30px;
    }
   
    .ps-shopping {
        padding: 80px 0;
    }

    .ps-shopping .ps-shopping__right {
        padding-left: 40px;
    }

    .ps-shopping__top {
        display: block;
        text-align: center;
    }

    .ps-shopping__top > * {
        max-width: 100%;
    }


    .ps-shopping__top__right {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .ps-shopping.ps-shopping--reverse .ps-shopping__right {
        padding-right: 40px;
    }
}

@media (max-width: 991px) {
    .ps-shopping {
        padding: 80px 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-flow: column-reverse wrap;
        flex-flow: column-reverse wrap;
    }

    .ps-shopping .ps-shopping__right {
        margin-bottom: 30px;
        padding-left: 0;
    }

    .ps-shopping.ps-shopping--reverse {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-flow: column-reverse wrap;
        flex-flow: column-reverse wrap;
    }

    .ps-shopping.ps-shopping--reverse .ps-shopping__right {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .ps-shopping {
        padding: 60px 0;
    }

    .ps-shopping__top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-shopping__top > * {
        max-width: 100%;
    }

    .ps-shopping__top p {
        margin-bottom: 20px;
    }

    .ps-shopping__top figure {
        text-align: left;
    }

    .ps-shopping__top__right {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-shopping__top__right p {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .ps-shopping {
        padding: 50px 0;
    }
    .ps-product__categories li {
        display: inline-block;
        vertical-align: top;
        margin: 6px 8px;
    }
    .ps-product__categories li a {
        font-size: 12px;
    }

    .ps-shopping__top {
        text-align: center;
    }

    .ps-shopping__top p {
        font-size: 16px;
    }

    .ps-shopping__top figure {
        text-align: center;
    }

    .ps-shopping__top figure p {
        line-height: 1.8em;
    }

    .ps-shopping__top figure .select2 {
        margin-bottom: 20px;
        min-width: 100%;
        max-width: 100%;
    }

    .ps-shopping__top figure .select2 .select2-selection--single .select2-selection__rendered {
        font-size: 16px;
    }
}

.ps-contact {
    padding-bottom: 120px;
}

.ps-contact #contact-map {
    position: relative;
    z-index: -1;
    top: -40px;
    margin-bottom: -40px;
    min-height: 690px;
}

.ps-contact .ps-section__header {
    padding: 120px 0 60px;
}

.ps-contact .ps-section__header h3 {
    margin-bottom: 0;
}

.ps-contact .ps-section__content {
    padding-bottom: 90px;
}

.ps-contact .ps-section__content figure {
    margin-bottom: 20px;
}

.ps-contact .ps-section__content figure figcaption {
    margin-bottom: 5px;
    font-weight: 700;
    color: #ce873a;
    text-transform: uppercase;
}

.ps-contact .ps-section__content figure p {
    margin-bottom: 0;
    color: #555555;
    line-height: 1.8em;
    font-size: 18px;
}

.ps-contact .ps-section__content figure p a:hover {
    color: #ce873a;
}

@media (max-width: 1199px) {
    .ps-contact .ps-section__header {
        padding: 100px 0;
    }
}

@media (max-width: 991px) {
    .ps-contact .ps-section__header {
        padding: 80px 0;
    }
}

@media (max-width: 767px) {
    .ps-contact .ps-section__header {
        padding: 60px 0;
    }
}

@media (max-width: 479px) {
    .ps-contact .ps-section__header {
        padding: 50px 0;
    }

    .ps-contact .ps-section__header h3 {
        font-size: 24px;
    }

    .ps-contact .ps-section__content {
        padding-bottom: 40px;
    }
}

.ps-blog {
    padding: 60px 0 120px;
}

.ps-blog .ps-blog__footer {
    padding-top: 60px;
    text-align: center;
}

@media (max-width: 991px) {
    .ps-blog {
        padding: 80px 0 100px;
    }
}

@media (max-width: 767px) {
    .ps-blog {
        padding: 60px 0;
    }
}

@media (max-width: 479px) {
    .ps-blog {
        padding: 50px 0;
    }
}

.ps-blog--sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.ps-blog--sidebar .ps-blog__left {
    padding-right: 60px;
}

.ps-blog--sidebar .ps-blog__left .ps-post {
    margin-bottom: 40px;
}

.ps-blog--sidebar .ps-blog__right {
    max-width: 270px;
}


@media (max-width: 991px) {
    .ps-blog--sidebar {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-blog--sidebar .ps-blog__left {
        padding-right: 0;
        padding-bottom: 50px;
    }
}

.ps-blog-comments > h3 {
    margin-bottom: 40px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.ps-blog-comments > h3 span {
    color: #ce873a;
}

.ps-whishlist {
    padding: 100px 0;
}

@media (max-width: 767px) {
    .ps-whishlist {
        padding: 60px 0;
    }
}

@media (max-width: 479px) {
    .ps-whishlist {
        padding: 50px 0;
    }
}

.ps-shopping-cart {
    padding: 100px 0;
}

.ps-shopping-cart .ps-section__actions {
    padding-top: 50px;
    padding-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ps-shopping-cart .ps-section__actions .ps-btn {
    padding: 10px 30px;
    margin-bottom: 20px;
}

.ps-shopping-cart .ps-section__actions .ps-btn--outline {
    margin-right: 30px;
    padding: 10px 30px;
    font-weight: 700;
    color: #000;
}

.ps-shopping-cart .ps-section__actions .ps-btn--outline:last-child {
    margin-right: 0;
}

.ps-shopping-cart .ps-section__actions .ps-btn--outline:hover {
    color: #ffffff;
}


.ps-shopping-cart .ps-shopping-cart__total {
    max-width: 500px;
    padding: 30px 40px;
    background-color: #f7f3ee;
}

.ps-shopping-cart .ps-shopping-cart__total figcaption {
    margin-bottom: 30px;
    font-size: 30px;
    color: #555;
    font-weight: 700;
    border-bottom: 1px solid #e1e1e1;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr.total {
    border-top: 1px solid #e1e1e1;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr.total td:first-child {
    font-weight: 700;
    color: #000;
    line-height: 50px;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr.total td:last-child {
    font-size: 30px;
    color: #ce873a;
    font-weight: 700;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td {
    border: none;
    vertical-align: top;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td:first-child {
    text-transform: uppercase;
    color: #555;
    font-weight: 700;
    line-height: 30px;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td:first-child strong {
    font-weight: 700;
    color: #000;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td:last-child {
    font-size: 24px;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td .ps-radio {
    margin-bottom: 0px;
}

.ps-shopping-cart .ps-shopping-cart__total table tbody tr td .ps-radio > label {
    font-weight: 700;
    color: #000;
    font-size: 18px;
    line-height: 20px;
}

.ps-shopping-cart .ps-shopping-cart__coupon {
    padding-right: 100px;
}

.ps-shopping-cart .ps-shopping-cart__coupon p {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #555;
    line-height: 1.4em;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group input {
    border: none;
    height: 40px;
    text-indent: 15px;
    background-color: #f6f7fb;
    border-radius: 50px;
    max-width: 370px;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group input::-webkit-input-placeholder {
    color: #555;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group input::-moz-placeholder {
    color: #555;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group input:-moz-placeholder {
    color: #555;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group input:-ms-input-placeholder {
    color: #555;
}

.ps-shopping-cart .ps-shopping-cart__coupon .form-group button {
    height: 40px;
    padding: 0 30px;
}

.ps-shopping-cart .ps-section__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

@media (max-width: 1199px) {
    .ps-shopping-cart .ps-section__footer {
        display: block;
    }
}

.ps-shopping-cart .ps-section__footer .ps-shopping-cart__coupon {
    max-width: 500px;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 40px;
    padding-right: 0;
}

.ps-shopping-cart .ps-section__footer .ps-shopping-cart__coupon input {
    max-width: 300px;
}

.ps-shopping-cart .ps-section__footer .ps-shopping-cart__total {
    margin-left: auto;
    margin-right: 0;
}

@media (max-width: 479px) {
    .ps-shopping-cart .ps-section__actions {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-shopping-cart .ps-section__actions .ps-btn {
        width: 100%;
        text-align: center;
    }

    .ps-shopping-cart .ps-shopping-cart__coupon .form-group {
        display: block;
    }

    .ps-shopping-cart .ps-shopping-cart__coupon .form-group input {
        margin-bottom: 20px;
    }

    .ps-shopping-cart .ps-shopping-cart__coupon .form-group button {
        text-align: center;
        width: 100%;
    }

    .ps-shopping-cart .ps-shopping-cart__total {
        padding: 40px 20px;
    }

    .ps-shopping-cart .ps-shopping-cart__total table tbody > tr > td .ps-radio label {
        font-size: 14px;
    }

    .ps-shopping-cart .ps-shopping-cart__total .ps-btn--fullwidth {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.ps-page--profile h2{
    font-size: 32px;
}

.ps-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    padding: 100px 0;
}

.ps-profile .ps-profile__right {
    max-width: 500px;
}

.ps-profile .ps-profile__header {
    margin-bottom: 35px;
}

.ps-profile .ps-profile__header p {
    font-size: 18px;
    color: #555;
    font-weight: 600;
}

.ps-profile .ps-profile__header p a {
    color: #000;
    font-weight: 700;
}

.ps-profile .ps-profile__header p a:hover {
    color: #ce873a;
}

.ps-profile .ps-profile__left {
    padding-right: 100px;
}

@media (max-width: 1199px) {
    .ps-profile .ps-profile__left {
        padding-right: 30px;
    }
}

@media (max-width: 991px) {
    .ps-page--profile h2{
        font-size: 24px;
    }

    .ps-profile {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    .ps-profile .ps-profile__left {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .ps-profile {
        padding: 60px 0;
    }
}

@media (max-width: 479px) {
    .ps-profile {
        padding: 50px 0;
    }
}

.ps-404 {
    padding: 120px 0;
    text-align: center;
}

.ps-404 img {
    margin-bottom: 40px;
}

.ps-404 h2 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
}

.ps-404 h4 {
    margin-bottom: 25px;
    font-size: 18px;
    color: #555;
}

.ps-404 p {
    font-size: 18px;
    font-weight: 600;
}

.ps-404 p a {
    text-decoration: underline;
    color: #ce873a;
}

.ps-404 .form-group--inside {
    max-width: 570px;
    margin: 0 auto;
}

.ps-404 .form-control {
    border: none;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #ce873a;
}

@media (max-width: 1199px) {
    .ps-404 {
        padding: 100px 0;
    }
}

@media (max-width: 991px) {
    .ps-404 {
        padding: 80px 0;
    }
}

@media (max-width: 479px) {
    .ps-404 img {
        max-height: 120px;
    }

    .ps-404 h2 {
        font-size: 30px;
    }

    .ps-404 h4 {
        font-size: 16px;
    }
}

.ps-related-product {
    padding-bottom: 120px;
    padding-top: 12px;
    background-color: #f3f3f3;
}

.ps-related-product .ps-section__footer {
    padding-top: 40px;
    text-align: center;
}

@media (max-width: 1199px) {
    .ps-related-product {
        padding: 90px 0;
    }
}

@media (max-width: 991px) {
    .ps-related-product {
        padding: 80px 0;
    }
}

@media (max-width: 767px) {
    .ps-related-product {
        padding: 60px 0;
    }
}

@media (min-width: 1440px) {
    .five-column .row .col-xl-3 {
        max-width: 20%;
    }
}

@media (min-width: 1200px) {
    .ps-home-banner .owl-slider .owl-nav {
        padding: 0 50px;
    }
}

@media (min-width: 1440px) {
    .ps-home-banner .owl-slider .owl-nav {
        padding: 0 80px;
    }
}


#homepage .ps-home-banner {
    position: relative;
    z-index: 10;
    height: 100vh;
    width: 100%;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    mix-blend-mode: multiply;
    background-position: bottom!important;
    background-size: cover;
}

#homepage .ps-home-banner .owl-slider {
    position: static;
}

#homepage .ps-home-banner .owl-slider .owl-stage-outer {

    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

#homepage .ps-home-banner .owl-slider .owl-stage-outer .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
}

@media (max-width: 1680px) and (min-width: 1200px) {
    #homepage .ps-home-banner .owl-slider {
        padding-top: 100px;
    }
}

@media (min-width: 1200px) {
    #homepage .ps-home-banner .owl-slider .owl-nav {
        padding: 0 50px;
    }
}

@media (min-width: 1440px) {
    #homepage .ps-home-banner .owl-slider .owl-nav {
        padding: 0 80px;
    }
}

#homepage .ps-section .ps-section__header i {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1em;
}

@media (max-width: 1199px) {
    #homepage .ps-home-booking {
        padding: 120px 0 80px;
    }
}

@media (max-width: 991px) {
    #homepage .ps-home-booking {
        padding: 100px 0 60px;
    }
}

@media (max-width: 767px) {
    #homepage .ps-home-banner{
        background-size: auto;
    }
    #homepage .ps-home-booking {
        padding: 100px 0 60px;
    }

    #homepage .ps-home-booking .ps-section__header h5 {
        font-size: 16px;
    }

    #homepage .ps-home-booking .form-group--icon {
        margin-bottom: 20px;
    }
}

@inclhahaude media("<xs") {
    #homepage .ps-home-booking {
        padding: 100px 0 40px;
    }
}

#homepage .ps-home-our-awards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
}

#homepage .ps-home-our-awards .ps-section__header {
    padding-bottom: 50px;
    text-align: center;
}

#homepage .ps-home-our-awards .ps-section__header i {
    font-size: 24px;
}

#homepage .ps-home-our-awards .ps-section__header h3 {
    color: #ffffff;
}

#homepage .ps-home-our-awards .ps-section__header h5 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    font-style: italic;
    line-height: 1.4em;
}

#homepage .ps-home-our-awards .ps-section__left {
    max-width: 33.33333%;
}

#homepage .ps-home-our-awards .ps-section__instagram {
    position: relative;
    z-index: 20;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

#homepage .ps-home-our-awards .ps-section__instagram:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

#homepage .ps-home-our-awards .ps-section__instagram p {
    color: #ce873a;
    text-transform: uppercase;
}

#homepage .ps-home-our-awards .ps-section__instagram h4 {
    margin-bottom: 30px;

    font-size: 32px;
    color: #ffffff;
}

#homepage .ps-home-our-awards .ps-section__instagram .ps-instagram {
    display: flex;
    position: relative;
    width: auto;
    background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
    border-radius: 8px;
    color: #ffffff;
    padding: 8px;
    line-height: 1.5;
    align-items: center;
    gap: 8px;
}

#homepage .ps-home-our-awards .ps-section__instagram .ps-instagram i {

    color: #ffffff;
    font-size: 32px;
}

#homepage .ps-home-our-awards .ps-section__instagram .ps-video:hover {
    background-color: #ab6d2a;
}

@media (max-width: 991px) {
    #homepage .ps-home-our-awards .ps-section__instagram h4 {
        font-size: 32px;
    }
}

@media (max-width: 479px) {
    #homepage .ps-home-our-awards .ps-section__instagram h4 {
        font-size: 24px;
    }

    #homepage .ps-home-our-awards .ps-section__instagram .ps-video {
        width: 60px;
        height: 60px;
    }
}

#homepage .ps-home-our-awards .ps-section__right {
    padding: 75px 150px;
    max-width: 66.666666%;
    background-color: #21202d;
}

@media (max-width: 1680px) {
    #homepage .ps-home-our-awards .ps-section__right {
        padding: 75px 100px;
    }
}

@media (max-width: 1480px) {
    #homepage .ps-home-our-awards .ps-section__right {
        max-width: 70%;
    }

    #homepage .ps-home-our-awards .ps-section__left {
        max-width: 30%;
    }

    #homepage .ps-home-our-awards .ps-section__instagram h4 {
        font-size: 36px;
    }
}

#homepage .ps-home-our-awards .ps-block--award img {
    max-width: 100px;
}

@media (max-width: 1199px) {
    #homepage .ps-home-our-awards {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    }

    #homepage .ps-home-our-awards .ps-section__left {
        max-width: 100%;
    }

    #homepage .ps-home-our-awards .ps-section__right {
        padding: 60px 40px;
    }

    #homepage .ps-home-our-awards .ps-block--award img {
        max-height: 100px;
    }
}

@media (max-width: 991px) {
    #homepage .ps-home-our-awards {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }

    #homepage .ps-home-our-awards .ps-section__left figure {
        padding: 150px 30px;
        height: 100%;
    }

    #homepage .ps-home-our-awards .ps-section__right {
        max-width: 100%;
        padding: 30px;
    }
}

@media (max-width: 767px) {
    #homepage .ps-home-our-awards .ps-section__instagram h4 {
        font-size: 24px;
    }
}

@media (max-width: 479px) {
    #homepage .ps-home-our-awards .ps-section__left {
        padding: 50px 30px;
    }
}

#homepage .ps-home-product {
    padding: 120px 0;
    background-color: #f3f3f3;
}

#homepage .ps-home-product .ps-section__footer {
    padding-top: 10px;
    text-align: center;
}

#homepage .ps-home-product .ps-section__footer .ps-btn {
    padding: 15px 36px;
    font-size: 14px;
    color: #464646;
    border-radius: 100px;
    border: 1px solid #464646;
    text-transform: uppercase;
}

#homepage .ps-home-product .ps-section__footer .ps-btn:hover {
    background-color: #ce873a;
    border-color: #ce873a;
    color: #ffffff;
}

@media (min-width: 1440px) {
    #homepage .ps-home-product .container-fluid {
        padding: 0 100px;
    }
}

@media (min-width: 1200px) {
    #homepage .ps-home-product .row .col-xl-3 {
        max-width: 20%;
    }
}

@media (max-width: 1199px) {
    #homepage .ps-home-product {
        padding: 90px 0;
    }
}

@media (max-width: 991px) {
    #homepage .ps-home-product {
        padding: 70px 0;
    }
}

@media (max-width: 479px) {
    #homepage .ps-home-product {
        padding: 50px 0;
    }
}

#homepage .ps-home-menu-of-day {
    display: block;
    background-color: transparent;
}

#homepage .ps-home-menu-of-day .ps-section__header h3 {
    color: white;
}

#homepage .ps-home-menu-of-day .ps-block--product-menu .ps-block__header {
    border-top: 1px dashed #e7c8a6;
    border-bottom: 1px dashed #e7c8a6;
}

#homepage .ps-home-menu-of-day .ps-block--product-menu .ps-tab-list li a {
    color: #ce873a;
}

#homepage .ps-home-menu-of-day .ps-block--product-menu .ps-tab-list li.active a {
    color: white;
}

@media (max-width: 1199px) {
    #homepage .ps-home-menu-of-day {
        padding: 90px 0 80px;
    }
}

#homepage .ps-home-best-services .ps-section__container {
    background-color: #21202d;
}

@media (min-width: 1440px) {
    .ps-page .container-fluid {
        padding: 0 100px;
    }
}

@media (min-width: 1440px) {
    .ps-page--shop .container-fluid {
        padding: 0 100px;
    }
}

.ps-page--about .ps-hero {
    z-index: 11;
}

.ps-page--about .ps-home-awards {
    position: relative;
    top: -40px;
    margin-bottom: -40px;
    z-index: 1;
}

.ps-page--contact {
    z-index: 10;
}

.ps-page--product-detail .ps-product--detail {
    padding-top: 120px;
}

.ps-page--product-detail .ps-product--background {
    padding-top: 0;
}

.sub-toggle {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    display: none;
}

.sub-toggle:before, .sub-toggle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 1px;
    background-color: #000;
}

.sub-toggle:before {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.sub-toggle:after {
    -webkit-transform: translateX(-50%) rotate(90deg);
    -moz-transform: translateX(-50%) rotate(90deg);
    -ms-transform: translateX(-50%) rotate(90deg);
    -o-transform: translateX(-50%) rotate(90deg);
    transform: translateX(-50%) rotate(90deg);
}

.sub-toggle.active:after {
    display: none;
}

.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 275px;
    z-index: 1000;
    -webkit-transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    background-color: #f7e8da;
}

.sub-menu:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 0;
    width: 0;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    display: none;
}

.sub-menu > li {
    display: block;
    margin-bottom: 5px;
}

.sub-menu > li > a {
    display: block;
    padding: 6px 0px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.sub-menu > li > a:hover {
    color: #ce873a;
}

.sub-menu > li:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

.sub-menu > li:last-child a {
    border-bottom: 1px solid #eee;
}

.sub-menu > li.menu-item-has-children {
    position: relative;
}

.sub-menu > li.menu-item-has-children > .sub-toggle {
    display: none;
}

.sub-menu > li.menu-item-has-children > .sub-menu {
    position: absolute;
    top: 0;
    left: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}

.sub-menu > li.menu-item-has-children > .sub-menu:before {
    display: none;
}

.sub-menu > li.menu-item-has-children:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.menu {
    text-align: left;
}

.menu > li {
    display: inline-block;
}

.menu > li > a {
    display: inline-block;
    padding: 20px 25px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    color: #000;
}

.menu > li > a:hover {
    color: #000;
}

.menu > li:first-child {
    padding-left: 0;
}

.menu > li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.menu > li .sub-toggle {
    margin-left: 5px;
    display: none;
}

.menu > li .sub-menu {
    padding: 25px;
    -webkit-transform: scale3d(0, 1, 1);
    -moz-transform: scale3d(0, 1, 1);
    -ms-transform: scale3d(0, 1, 1);
    -o-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.menu > li.menu-item-has-children {
    position: relative;
}

.menu > li:hover .sub-toggle {
    color: #ffffff;
}

.menu > li:hover > .sub-menu {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.menu > li:hover.menu-item-has-children > a:before {
    visibility: visible;
    opacity: 1;
}

.menu--fullscreen > li {
    margin-bottom: 20px;
}

.menu--fullscreen > li > a {
    display: block;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2em;
    color: #ffffff;
}

.menu--fullscreen > li > a:hover {
    color: #ce873a;
}

.menu--mobile .sub-menu {
    position: relative;
    display: none;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0s ease;
    -moz-transition: all 0s ease;
    -o-transition: all 0s ease;
    transition: all 0s ease;
    border: none;
}

.menu--mobile .sub-menu > li > a {
    border: none;
    padding: 10px 20px;
}

.menu--mobile > li {
    border-bottom: 1px solid #dedede;
}

.menu--mobile > li > a {
    position: relative;
    z-index: 10;
    display: block;
    padding: 15px 20px;
    line-height: 20px;
    font-weight: 600;
    font-size: 20px;
}

.menu--mobile > li > a:hover {
    padding-left: 5px;
    color: #000;
}

.menu--mobile > li.menu-item-has-children {
    position: relative;
}

.menu--mobile > li.menu-item-has-children .sub-toggle {
    position: absolute;
    top: 5px;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 20;
    display: inline-block;
}

/*4. MODULES */
.navigation {
    background-color: #ce873a;
}

.navigation > .ps-container, .navigation > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.navigation > .ps-container > *, .navigation > .container > * {
    width: 100%;
}

.navigation__extra > li {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    padding-right: 20px;
}

.navigation__extra > li:after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 15px;
    background-color: #000;
}

.navigation__extra > li a {
    color: #000000;
}

.navigation__extra > li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.navigation__extra > li:last-child:after {
    display: none;
}

.navigation__extra .ps-dropdown img {
    margin-right: 8px;
}

.navigation .navigation__left {
    max-width: 260px;
}

.navigation .navigation__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 35px;
}

.header .header__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header .header__actions > * {
    margin-right: 30px;
}

.header .header__actions > a {
    color: #ffffff;
}

.header .header__actions > a i:before {
    font-size: 20px;
}

.header .header__actions > a:hover {
    color: #ce873a;
}

.header .ps-logo img {
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    mix-blend-mode: multiply;
}

.header.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding-top: 10px;
    padding-bottom: 10px;
}


.header.header--sticky .ps-logo img {
    max-height: 50px;
}

@media (max-width: 1199px) {
    .header {
        display: none;
    }

    .header.header--mobile {
        display: block;
    }
}

.header--1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 44px 100px;
}

.header--1 .ps-logo {
    display: inline-block;
    vertical-align: top;
}

.header--1 .ps-logo img {
    max-height: 85px;
}

.header--1 .header__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header--1 .header__right p {
    margin-left: 35px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
}

.header--1 .header__right p i {
    margin-right: 10px;
}

.header--1 .header__center {
    text-align: center;
}

.header--1 .header__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-start;
}

.header--1.header--sticky {
    background-color: #000;
}

@media (max-width: 1440px) {
    .header--1 {
        padding: 20px 30px;
    }
}

@media (max-width: 1199px) {
    .header--1 {
        display: none;
    }
}

.header--default {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 44px 100px;
}

.header--default .header__navigation {
    background-color: #e1d0be;
    position: relative;
}

.header--default .header__navigation.left {
    border-radius: 50px 0 0 50px;
    text-align: right;
    padding-right: 60px;
}

.header--default .header__navigation.left:after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -50px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #fff4e9;
    z-index: 10;
}

.header--default .header__navigation.left .menu {
    text-align: right;
}

.header--default .header__navigation.left .menu .sub-menu {
    text-align: left;
}

.header--default .header__navigation.right {
    padding-left: 60px;
    border-radius: 0 50px 50px 0;
}

.header--default .header__navigation.right:before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -50px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #fff4e9;
    z-index: 10;
}

.header--default .ps-logo {
    display: inline-block;
    vertical-align: top;
}

.header--default .ps-logo img {
    max-height: 85px;
}

.header--default .menu > li > a:hover {
    background-color: #f7e8da;
}

.header--default .header__actions > a {
    color: #000;
}

.header--default .header__actions .ps-cart--mini > .ps-cart__toggle {
    color: #000;
}

.header--default .header__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 20%;
}

.header--default .header__right p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    color: #000;
}

.header--default .header__right p i {
    margin-right: 10px;
}

.header--default .header__center {
    max-width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header--default .header__center .header__logo {
    position: relative;
    text-align: center;
    max-width: 120px;
    z-index: 20;
}

.header--default .header__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 20%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header--default.header--sticky {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.header--default.header--sticky .header__navigation.left, .header--default.header--sticky .header__navigation.right {
    background-color: transparent;
}

.header--default.header--sticky .header__navigation.left:before, .header--default.header--sticky .header__navigation.left:after, .header--default.header--sticky .header__navigation.right:before, .header--default.header--sticky .header__navigation.right:after {
    display: none;
}

.header--default.header--sticky .header__right p {
    color: #000;
}

.header--default.header--sticky .header__center .menu > li > a {
    color: #000;
}

.header--default.header--sticky .header__left .header__actions > a {
    color: #000;
}

.header--default.header--sticky .header__left .header__actions > a:hover {
    color: #ce873a;
}

.header--default.header--sticky .header__left .header__actions .ps-cart--mini .ps-cart__toggle > i {
    color: #000;
}

@media (max-width: 1680px) {
    .header--default {
        padding: 10px 30px;
    }

    .header--default .header__right {
        max-width: 200px;
    }

    .header--default .header__center {
        max-width: 100%;
    }

    .header--default .header__left {
        max-width: 200px;
    }
}

@media (max-width: 1366px) {
    .header--default {
        padding: 10px 15px;
    }

    .header--default .menu > li > a {
        padding: 20px 10px;
    }

    .header--default .header__left {
        max-width: 160px;
    }

    .header--default .header__center {
        max-width: 800px;
    }
}

@media (max-width: 1199px) {
    .header--default {
        display: none;
    }
}


.header--home-4 .menu > li {
    padding-right: 50px;
}

.header--home-4 .menu > li > a {
    padding: 15px 0;
}

.header--home-4 .menu > li > a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    width: 100%;
    height: 2px;
    background-color: #ce873a;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    visibility: hidden;
    opacity: 0;
}

.header--home-4 .menu > li > a:hover {
    color: #ce873a;
    background-color: transparent;
}

.header--home-4 .menu > li > a:hover:before {
    visibility: visible;
    opacity: 1;
}

.header--home-4 .menu > li.current-menu-item .menu {
    position: relative;
}

.header--home-4 .header__navigation {
    background-color: transparent;
}

.header--home-4 .header__navigation.left:after {
    display: none;
}

.header--home-4 .header__navigation.left .menu li:last-child {
    padding-right: 0;
}

.header--home-4 .header__navigation.right:before {
    display: none;
}

.header--home-4 .header__navigation.right .menu {
    top: 0;
    right: 0;
}

.header--home-4 .header__center .header__logo {
    max-width: 160px;
}

.header--home-4.white .menu > li > a {
    color: #ffffff;
}

.header--home-4.white .header__actions > a {
    color: #ffffff;
}

.header--home-4.white .header__actions .ps-cart--mini .ps-cart__toggle {
    color: #ffffff;
}

.header--home-4.white .header__right p {
    color: #ffffff;
}

.header--home-4.white .header__right .ps-list--social li a {
    color: #ffffff;
}

.header--home-4.white .header__right .ps-list--social li a:hover {
    color: #ce873a;
}

.header--home-4.header--sticky .header__right p {
    color: #000;
}

.header--home-4.header--sticky.white .header__right .ps-list--social li a {
    color: #555;
}

.header--home-4.header--sticky.white .header__right .ps-list--social li a:hover {
    color: #ce873a;
}

.header--home-4.line {
    border-bottom: 1px dashed #e7c8a6;
}

.header--home-4.line.header--sticky {
    border-bottom: none;
}

@media (max-width: 1366px) {
    .header--home-4 .menu > li {
        padding-right: 25px;
    }

    .header--home-4 .header__navigation.left {
        padding-right: 40px;
    }

    .header--home-4 .header__navigation.right {
        padding-left: 40px;
    }
}

.header--left .ps-list--social {
    margin-right: 30px;
}

.header--left .ps-list--social li a {
    color: #ffffff;
}

.header--left .ps-list--social li a:hover {
    color: #ce873a;
}

.header--left .header__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header--left.header--sticky .ps-list--social li a {
    color: #555;
}

.header--left.header--sticky .menu > li a {
    color: #000;
}

.header--left.header--sticky .header__actions > a {
    color: #000;
}

.header--left.header--sticky .header__actions .ps-cart--mini .ps-cart__toggle {
    color: #000;
}

.header--fullscreen {
    position: relative;
}

.header--fullscreen .header__top {
    text-align: center;
}

.header--fullscreen .header__top .ps-logo {
    display: inline-block;
}

.header--fullscreen .header__top .ps-logo img {
    max-height: 100px;
}

.header--fullscreen .header__content {
    text-align: center;
    height: 100%;
    padding-top: 200px;
}

.header--fullscreen .header__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 60px;
    text-align: center;
}

.header--fullscreen .header__bottom .ps-list--social {
    margin-bottom: 20px;
}

.header--fullscreen .header__bottom .ps-list--social > li > a {
    font-size: 18px;
    color: #ffffff;
}

.header--fullscreen .header__bottom .ps-list--social > li > a:hover {
    color: #ce873a;
}

.header--fullscreen .header__bottom p {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 1366px) {
    .header--fullscreen .header__content {
        padding-top: 100px;
    }

    .header--fullscreen .header__bottom {
        padding-bottom: 20px;
    }

    .header--fullscreen .header__bottom .ps-list--social {
        margin-bottom: 0;
    }
}

.header--mobile {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
    text-align: center;
    padding: 0;
    border-bottom: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    background-color: #fff6ec;
    -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -o-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.header--mobile .ps-logo img {
    max-height: 40px;
}

.header--mobile .header__actions > a {
    margin-right: 20px;
}

.header--mobile .header__actions > a > i {
    color: #ce873a;
}

.header--mobile .header__actions > a > i:before {
    font-size: 20px;
}

.header--mobile .header__actions > a:last-child {
    margin-right: 0;
}

.header--mobile .header__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header--mobile .header__right .ps-toggle--sidebar {
    color: #ce873a;
}

.header--mobile .header__right .ps-toggle--sidebar i {
    font-size: 20px;
    font-weight: 300;
}

.header--mobile .header__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    padding: 10px 20px;
}

.header--mobile .header__content .header__right .header__actions {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.header--mobile .header__content .header__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header--mobile.header--sticky {
    /* position: relative; */
    padding: 0;
}

.header--mobile.header--sticky .navigation--mobile {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ce873a;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
    -ms-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
}

@media (min-width: 1200px) {
    .header--mobile {
        display: none;
    }
}

.navigation--sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    overflow-y: auto;
    z-index: 10001;
    background-color: #fff;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -o-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.navigation--sidebar .navigation__actions {
    text-align: center;
}

.navigation--sidebar .navigation__actions .ps-dropdown {
    margin-bottom: 10px;
}

.navigation--sidebar .navigation__actions .ps-dropdown > a {
    font-size: 1.4rem;
    color: #555;
}

.navigation--sidebar .navigation__header {
    position: relative;
    text-align: center;
    padding: 15px 0;
    background-color: #ce873a;
}

.navigation--sidebar .navigation__header h3 {
    margin-bottom: 0;
    font-weight: 600;
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: .05em;
}

.navigation--sidebar .navigation__header .ps-btn--close {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
}

.navigation--sidebar .navigation__header .ps-btn--close:before, .navigation--sidebar .navigation__header .ps-btn--close:after {
    background-color: #ffffff;
    height: 60%;
}

.navigation--sidebar .navigation__content {
    padding-top: 10px;
    padding-bottom: 70px;
}

.navigation--sidebar .header__actions a {
    display: inline-block;
    margin: 0 5px;
    line-height: 40px;
}

.navigation--sidebar .header__actions a i {
    font-size: 24px;
    color: #000;
    vertical-align: middle;
}

.navigation--sidebar .header__actions .ps-dropdown a {
    padding-right: 0;
}

.navigation--sidebar .header__actions .ps-dropdown a:after {
    display: none;
}

.navigation--sidebar .header__actions .ps-cart-toggle {
    position: relative;
    display: inline-block;
    padding: 5px 0;
    line-height: 30px;
}

.navigation--sidebar .header__actions .ps-cart-toggle span {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translate(25%, -100%);
    -moz-transform: translate(25%, -100%);
    -ms-transform: translate(25%, -100%);
    -o-transform: translate(25%, -100%);
    transform: translate(25%, -100%);
    background-color: #000;
}

.navigation--sidebar .header__actions .ps-cart-toggle span i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-style: normal;
    font-size: 11px;
}

.navigation--sidebar.active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

@media (min-width: 1200px) {
    .navigation--sidebar .navigation__actions {
        display: none;
    }
}

@media (max-width: 479px) {
    .navigation--sidebar {
        width: 100%;
    }
}

.navigation--list {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10002;
    padding: 10px 30px;
    background-color: #ce873a;
}

.navigation--list .navigation__item {
    text-align: center;
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    flex-basis: fit-content;
    flex-flow:column;
}

.navigation--list .navigation__item i {
    font-size: 20px;
    color: #ffffff;
}

.navigation--list .navigation__item span {
    display: block;
}

.navigation--list .navigation__item.active {
    background-color: #fff;
}

.navigation--list .navigation__item.active i {
    color: #ce873a;
    font-size: 18px;
}

.navigation--list .navigation__item.active span {
    color: #ce873a;
}
.navigation--list .navigation__item span{
    font-size: 10px;
    display: block;
}

.navigation--list .navigation__content {
    max-width: 300px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (min-width: 1200px) {
    .navigation--list {
        display: none;
    }
}

.navigation--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
}

.navigation--sticky .navigation {
    background-color: #fff;
}

.navigation--sticky .header__top {
    display: none;
}

.navigation--sticky.navigation--pin {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.navigation--sticky.navigation--unpin {
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
    transform: translate(0, -100%);
}

.navigation--sticky.header--1.navigation--pin .navigation {
    background-color: rgba(0, 0, 0, 0.95);
}

.navigation--sticky.header--1.navigation--pin.header--black .navigation {
    background-color: rgba(255, 255, 255, 0.95);
}

.navigation--sticky.header--2.navigation--pin.active {
    background-color: #fff;
    -webkit-transform: translate(270px, 0);
    -moz-transform: translate(270px, 0);
    -ms-transform: translate(270px, 0);
    -o-transform: translate(270px, 0);
    transform: translate(270px, 0);
}

@media (max-width: 1199px) {
    .navigation--sticky.navigation--unpin {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.ps-site-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    visibility: hidden;
    opacity: 0;
}

.ps-site-overlay.active {
    visibility: visible;
    opacity: 1;
}

.ps-search--mobile {
    max-width: 450px;
    margin: 0 auto;
    padding: 10px 20px;
}

.ps-site-info {
    text-align: center;

}

.ps-site-info .ps-logo {
    display: inline-block;
    margin-bottom: 25px;
}

.ps-site-info p {
    margin-bottom: 10px;
    color: #707070;
}

.ps-site-info p a:hover {
    color: #ce873a;
}

.ps-hightlight {
    color: #d18e45;
}

@media (max-width: 767px) {
    .ps-site-info .ps-logo {
        margin-bottom: 30px;
    }
}

.ps-footer {
    padding-top: 110px;
    background-color: #fbefe2;
}

.ps-footer .widget_footer .ps-list--payment-method {
    margin-top: 40px;
}

.ps-footer .widget_footer .ps-list--payment-method li a {
    color: #636363;
}

.ps-footer__content {
    padding-bottom: 80px;
}

.ps-footer__content .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ps-footer__content .ps-footer__left,
.ps-footer__content .ps-footer__center,
.ps-footer__content .ps-footer__right {
    width: 100%;
    color: #c2c2c2
}

.ps-footer__content .ps-footer__center a img {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1)
}

.ps-footer__content .ps-footer__left .ps-footer__left__items > div {
    display: block;
    margin-bottom: 10px;
}

.ps-footer__bottom {
    padding: 40px 0;
    border-top: 1px solid #f2dac0;
    text-align: center;
}

.ps-footer__bottom .ps-list--social {
    display: inline-block;
    margin-bottom: 6px;
    vertical-align: top;
}

.ps-footer__bottom p {
    margin-bottom: 0;
}

.ps-footer--dark {
    background-color: #21202d;
}

.ps-footer--dark .ps-site-info p {
    color: #c2c2c2;
}

.ps-footer--dark .widget_footer .widget-title {
    color: #ffffff;
}

.ps-footer--dark .widget_footer p {
    color: #c2c2c2;
}

.ps-footer--dark .widget_footer .ps-list--payment-method {
    margin-top: 40px;
}

.ps-footer--dark .widget_footer .ps-list--payment-method li a {
    color: #c2c2c2;
}

.ps-footer--dark .ps-footer__bottom {
    border-top-color: #363636;
}

.ps-footer--dark .ps-footer__bottom p {
    color: #c2c2c2;
}

.ps-footer--dark .ps-footer__bottom p a {
    color: #ffffff;
}

.ps-footer--dark .ps-footer__bottom p a:hover {
    color: #ce873a;
}

.ps-footer--dark .ps-footer__bottom .ps-list--social li a {
    color: #c2c2c2;
}

.ps-footer--light {
    background-color: #f7f3ee;
}

.ps-footer .ps-logo {
    height: 150px;
    width: auto;
}

@media (max-width: 1199px) {
    .ps-footer {
        padding-top: 80px;
        padding-bottom: 60px;
    }

    .ps-footer__content .container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .ps-footer__content .container > * {
        max-width: 100%;
    }

    .ps-footer__content .container .ps-footer__left {
        margin-bottom: 20px;
        width: 100%;
    }

    .ps-footer__content .container .ps-footer__center {
        margin-bottom: 30px;
        width: 100%;
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
}

@media (max-width: 767px) {
    .ps-footer {
        padding-top: 60px;
    }

    .ps-footer__content {
        padding-bottom: 60px;
    }

    .ps-footer__content .container .ps-footer__left {
        max-width: 100%;
    }

    .ps-footer__content .container .ps-footer__right {
        width: 100%;

    }


    .ps-footer .ps-form--footer-subscribe {
        text-align: center;
        margin-bottom: 30px;
    }

    .ps-footer .widget_footer {
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: center;
    }

    .ps-footer .ps-footer__content {
        padding-bottom: 50px;
    }

    .ps-footer__content .ps-footer__left .ps-footer__left__items {
        padding-bottom: 50px;
    }
}

@media (max-width: 479px) {
    .ps-footer {
        padding-top: 50px;
    }
}

.ps-footer--2 {
    padding: 115px 0;
}

.ps-footer--2 .ps-footer__bottom {
    max-width: 330px;
    margin: 30px auto 0;
    padding-top: 30px;
    padding-bottom: 0;
}

.ps-footer--2 .ps-footer__bottom p {
    color: #707070;
}

@media (max-width: 767px) {
    .ps-footer--2 {
        padding: 70px 0 115px;
    }
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

/*5. HELPERS */
.ma-0 {
    margin: 0;
}

.pd-0 {
    padding: 0;
}

.mt-0 {
    margin-top: 0px;
}

.mr-0 {
    margin-right: 0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.ml-0 {
    margin-left: 0px;
}

.pt-0 {
    padding-top: 0px;
}

.pr-0 {
    padding-right: 0px;
}

.pb-0 {
    padding-bottom: 0px;
}

.pl-0 {
    padding-left: 0px;
}

.mt-5 {
    margin-top: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.pt-5 {
    padding-top: 5px;
}

.pr-5 {
    padding-right: 5px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pl-15 {
    padding-left: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mr-25 {
    margin-right: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.ml-25 {
    margin-left: 25px;
}

.pt-25 {
    padding-top: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pl-25 {
    padding-left: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.pt-30 {
    padding-top: 30px;
}

.pr-30 {
    padding-right: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mr-35 {
    margin-right: 35px;
}

.mb-35 {
    margin-bottom: 35px;
}

.ml-35 {
    margin-left: 35px;
}

.pt-35 {
    padding-top: 35px;
}

.pr-35 {
    padding-right: 35px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pl-35 {
    padding-left: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.pt-40 {
    padding-top: 40px;
}

.pr-40 {
    padding-right: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mr-45 {
    margin-right: 45px;
}

.mb-45 {
    margin-bottom: 45px;
}

.ml-45 {
    margin-left: 45px;
}

.pt-45 {
    padding-top: 45px;
}

.pr-45 {
    padding-right: 45px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pl-45 {
    padding-left: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mr-50 {
    margin-right: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-50 {
    margin-left: 50px;
}

.pt-50 {
    padding-top: 50px;
}

.pr-50 {
    padding-right: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pl-50 {
    padding-left: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mr-55 {
    margin-right: 55px;
}

.mb-55 {
    margin-bottom: 55px;
}

.ml-55 {
    margin-left: 55px;
}

.pt-55 {
    padding-top: 55px;
}

.pr-55 {
    padding-right: 55px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pl-55 {
    padding-left: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mr-60 {
    margin-right: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.ml-60 {
    margin-left: 60px;
}

.pt-60 {
    padding-top: 60px;
}

.pr-60 {
    padding-right: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pl-60 {
    padding-left: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mr-65 {
    margin-right: 65px;
}

.mb-65 {
    margin-bottom: 65px;
}

.ml-65 {
    margin-left: 65px;
}

.pt-65 {
    padding-top: 65px;
}

.pr-65 {
    padding-right: 65px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pl-65 {
    padding-left: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mr-70 {
    margin-right: 70px;
}

.mb-70 {
    margin-bottom: 70px;
}

.ml-70 {
    margin-left: 70px;
}

.pt-70 {
    padding-top: 70px;
}

.pr-70 {
    padding-right: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pl-70 {
    padding-left: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mr-75 {
    margin-right: 75px;
}

.mb-75 {
    margin-bottom: 75px;
}

.ml-75 {
    margin-left: 75px;
}

.pt-75 {
    padding-top: 75px;
}

.pr-75 {
    padding-right: 75px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pl-75 {
    padding-left: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mr-80 {
    margin-right: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

.ml-80 {
    margin-left: 80px;
}

.pt-80 {
    padding-top: 80px;
}

.pr-80 {
    padding-right: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pl-80 {
    padding-left: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mr-85 {
    margin-right: 85px;
}

.mb-85 {
    margin-bottom: 85px;
}

.ml-85 {
    margin-left: 85px;
}

.pt-85 {
    padding-top: 85px;
}

.pr-85 {
    padding-right: 85px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pl-85 {
    padding-left: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mr-90 {
    margin-right: 90px;
}

.mb-90 {
    margin-bottom: 90px;
}

.ml-90 {
    margin-left: 90px;
}

.pt-90 {
    padding-top: 90px;
}

.pr-90 {
    padding-right: 90px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pl-90 {
    padding-left: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mr-95 {
    margin-right: 95px;
}

.mb-95 {
    margin-bottom: 95px;
}

.ml-95 {
    margin-left: 95px;
}

.pt-95 {
    padding-top: 95px;
}

.pr-95 {
    padding-right: 95px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pl-95 {
    padding-left: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-100 {
    margin-left: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pr-100 {
    padding-right: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pl-100 {
    padding-left: 100px;
}

.fw-300 {
    font-weight: 300;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.ps-fullwidth {
    width: 100%;
}

html .bg--parallax {
    position: relative;
    z-index: 10;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% 50%;
}

.bg--cover {
    background-position: 50% 50% !important;
    background-size: contain !important;
}

.bg--top {
    background-position: 0% 0% !important;
    background-size: cover !important;
}

.bg--top-right {
    background-position: 100% 100% !important;
    background-size: cover !important;
}

.bg--top-left {
    background-position: 0% 100% !important;
    background-size: cover !important;
}

.ps-block--product-menu ul, .ps-block--upcomming-event ul, .ps-panel--sidebar ul, .widget ul, .widget_footer ul, .widget_categories ul, .ps-carousel--animate ul, .ps-home-best-services .ps-section__content ul, .ps-shopping ul, .header ul, .header--fullscreen ul, .navigation--sidebar ul, .ps-block--product-menu ol, .ps-block--upcomming-event ol, .ps-panel--sidebar ol, .widget ol, .widget_footer ol, .widget_categories ol, .ps-carousel--animate ol, .ps-home-best-services .ps-section__content ol, .ps-shopping ol, .header ol, .header--fullscreen ol, .navigation--sidebar ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ps-post--sidebar > *, .ps-block--good-baker > *, .ps-block--comment > *, .ps-block--review > *, .ps-product--horizontal > *, .ps-product--horizontal .ps-product__container > *, .ps-product--horizontal-2 > *, .ps-product--sidebar > *, .ps-product--combo > *, .ps-product--cart > *, .ps-product--mini-cart > *, .ps-product--banner > *, .ps-home-menu-of-day > *, .ps-shopping > *, .ps-shopping__top > *, .ps-blog--sidebar > *, .ps-shopping-cart .ps-section__actions > *, .ps-shopping-cart .ps-section__footer > *, .ps-profile > *, .open-jobs .ps-section--good-baker .container > *, #homepage .ps-home-our-awards > *, .header--1 > *, .header--default > *, .header--default .header__center > *, .header--mobile > *, .header--mobile .header__content > * {
    width: 100%;
}

.no-spinners {
    -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.login-form {
    border-radius: 40px 0 40px 0;
}

.open-jobs .ps-home-booking .ps-section__header h3 {
    color: #ffffff;
}

.open-jobs .ps-home-booking {
    position: relative;
    padding: 180px 0 120px;
    top: -40px;
}

.open-jobs .ps-home-booking:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7f462c;
    z-index: -1;
}

.open-jobs .ps-home-booking .ps-section__header h3 {
    color: #ffffff;
}

.open-jobs .ps-home-booking .ps-section__header h5 {
    font-size: 22px;
    font-style: italic;
    color: #ffffff;
}

.open-jobs .ps-home-booking .ps-section__header h5 span {
    color: #ce873a;
}

#AdminProductForm .custom-select {
    height: 45px;
    font-size: 14px;
    border: 0;
    background-color: #f6f7fb;
    padding-right: 20px;
    padding-left: 20px;

}

.switch-btn {
    display: flex;

    align-items: center;
    justify-content: center;
    gap: 12px
}

/* Switch Button */
.switch-btn span {
    display: flex;
    align-items: center;
    width: 40px;
    height: 16px;
    border-radius: 15px;
    background-color: #ddd;
    transition: 0.3s;
    padding: 0 3px;
    position: relative;
}

.switch-btn span::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0px;
    transition: 0.6s;
    margin-left: 4px;
    margin-right: 4px;
}

.switch-btn input {
    display: none;
}

.switch-btn input:checked ~ span {
    background-color: #ce873a;
}

.switch-btn input:checked ~ span::before {
    left: 50%;
}


#adminPanel .nav-side-menu {
    z-index: 1001;
    right: 0;
    direction: rtl;
    text-align: right;
    overflow: auto;
    font-size: 12px;
    font-weight: 200;
    background-color: #2e353d;
    position: fixed;
    top: 0;
    width: 250px;
    height: 100%;
    color: #e1ffff;
}

#adminPanel .nav-side-menu .brand {
    background-color: #23282e;
    line-height: 50px;
    display: block;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
}

#adminPanel .nav-side-menu .toggle-btn {
    display: none;
}

#adminPanel .nav-side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 35px;
    cursor: pointer;
}

#adminPanel .nav-side-menu ul .active {
    border-right: 4px solid #ce873a;
    background-color: #4f5b69;
}

#adminPanel .nav-side-menu ul .sub-menu li.active {
    color: #ce873a;
}

#adminPanel .nav-side-menu ul .sub-menu li.active a {
    color: #ce873a;
}

#adminPanel .nav-side-menu ul .sub-menu li {
    background-color: #181c20;
    border: none;
    line-height: 28px;
    border-bottom: 1px solid #23282e;
    margin-right: 0;
}

#adminPanel .nav-side-menu ul .sub-menu li:hover {
    background-color: #020203;
    transition: none;
}

#adminPanel .nav-side-menu li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 35px;
    cursor: pointer;
    border-left: 3px solid #2e353d;
    border-bottom: 1px solid #23282e;
    transition: all 0.2s ease;
}

#adminPanel .nav-side-menu li .active {
    border-right: 4px solid #ce873a;
    background-color: #4f5b69;
}

#adminPanel .nav-side-menu li .sub-menu li.active {
    color: #ce873a;
}

#adminPanel .nav-side-menu li .sub-menu li.active a {
    color: #ce873a;
}

#adminPanel .nav-side-menu li .sub-menu li {
    background-color: #181c20;
    border: none;
    line-height: 28px;
    border-bottom: 1px solid #23282e;
    margin-right: 0;
}

#adminPanel .nav-side-menu li .sub-menu li:hover {
    background-color: #020203;
}

#adminPanel .nav-side-menu a li {
    text-decoration: none;
    color: #e1ffff;
}

#adminPanel .nav-side-menu a li i {
    margin: 10px 15px;
}

#adminPanel .nav-side-menu li:hover {
    border-right: 4px solid #ce873a;
    background-color: #4f5b69;
    transition: none;
}

#adminPanel .sub-menu li {
    padding-right: 20px;
}

#adminPanel body {
    margin: 0;
    padding: 0;
}

#adminPanel li .fa-chevron-down {
    margin: 10px;
    vertical-align: middle;
    float: right;
    transition: transform 0.4s;
}

#adminPanel li.active .fa-chevron-down {
    transform: rotateX(180deg);
}

@media (max-width: 767px) {
    #adminPanel .nav-side-menu {
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    #adminPanel .nav-side-menu .toggle-btn {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10 !important;
        padding: 3px;
        background-color: #fff;
        color: #000;
        width: 40px;
        text-align: center;
        border-radius: 4px;
    }

    #adminPanel .brand {
        text-align: left !important;
        font-size: 22px;
        padding-left: 20px;
        line-height: 50px !important;
    }
}

@media (min-width: 767px) {
    #adminPanel .nav-side-menu .menu-list .menu-content {
        display: block;
    }
}

@media (min-width: 767px) {
    #adminPanel .content {
        margin-right: 250px;
    }
}

#adminPanel .content {

    padding: 0px 20px;

}

#adminPanel select {
    border-left: 16px solid #f6f7fb;

}

.upload-heading {
    font-size: 36px;
    color: #007bff;
    margin-bottom: 30px;
}

.file-upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.upload-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #0056b3;
}

.branch-detail-page .owl-carousel .owl-stage {
    display: flex;
}

.branch-detail-page .owl-item {
    width: max-content !important;
}

.branch-detail-page .item {
    width: max-content;
}

.branch-detail-page .owl-carousel .owl-item img {
    width: auto;
    max-height: 460px;
    margin-left: auto;
    margin-right: auto;
}

.gold {
    color: gold !important; /* Color for the filled stars */
}
.toolbarClassName{
    border-top-left-radius: 1.5rem!important;
    border-top-right-radius: 1.5rem!important;
    background-color: #f6f7fb!important;

}
/*.rdw-option-wrapper{*/
/*    padding: 0.25rem 0.5rem;*/
/*}*/
.editorClassName {
    min-height: 500px;
    padding: 0.5rem;
}


input[type="file"] {
    display: none;
}
.cursor-pointer {
    cursor: pointer;
}

/* map */
@import url('./leaflet.css');
#map { height: 310px; }


.our-site{
    display: none;
}

#adminPanel .searchInputButton{
    margin-right: 0;
}

.just-print{
    display: none;
}
@media print {
    .nav-side-menu, button, a{
        display: none!important;
    }
    .ps-footer--dark .ps-footer__bottom p a{
        display: inline!important;
    }
    .our-site{
        display: block;
    }
    .content{
        width: 100%;
        margin-right: 0!important;
    }
    .ps-hero{
        min-height: 50px;
        border-bottom:1px solid black;
        margin-bottom: 30px;
    }
    .ps-hero__container{
        padding-top: 50px;
        margin-bottom: -50px;
    }
    .ps-hero__heading{
        margin-bottom: 0px;
        padding: 0 0 80px 0;
        box-sizing: border-box;
        
    }
    .dateFilterRage{
        display: none!important;
    }
    .no-print{
        display: none!important;
    }
    .product-mini-orders{
        border-left: 0 none!important;
    }
    .ps-block--product-menu .ps-product--horizontal{
        border-right: none;
    }
    .ps-product--horizontal .ps-product__content p{
        color: #000000;
    }
    .just-print{
        display: block!important;
    }
    .mb-print-0{
        margin-bottom: 0!important;
    }
    .w-print-30{
        width: 25%;
    }
    .col-6-print{
        margin: 0;
        padding: 0 0px 0 0;
        width: 50%!important;
        display: inline-block;
    }
    .col-6-print:before{
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 4px;
        border:1px solid #000;
        display: inline-block;
        margin-left: 10px;
        margin-top: 5px;
    }
    .font-big-print{
        font-size: 18px;
    }
    .table td, .table th,.table thead th{
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }
}

#cartPage .datepicker-input  {
    border: 1px solid #ccc;
    height: 45px;
    padding: 0 25px;
    text-align: center!important;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    border-radius: 50px;
    background-color: transparent;
    display: flex;
}
.searchInputButton {
    position: relative;
    display: flex;
    max-width: 350px;
    margin: 0 auto;
}
.snappfood .cls-1 {fill:#ec008c}
.searchInputButton input {
    direction: rtl;
    min-height: 44px;
}
.searchInputButton button{
    z-index: 1;
    background-color: transparent;
    color: gray;
    border-color: transparent;
    position: absolute;
    left: 0;
}
.jalaali.calendarContainer .dayWrapper button{
    color: gray;
}
.calendarContainer .selected button, .calendarContainer .selected button:active, .calendarContainer .selected button:focus, .calendarContainer .selected button:hover :not([disabled]){
    color: white!important;
}
.calendarContainer .heading .title{
    color: black;
    background-color: #f3f3f3;
}
.calendarContainer .monthsList button{
 color: black;
}

.product-mini-orders{
    border-left: 1px dashed #50332c;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.product-mini-orders:nth-child(3n){
    border-left: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.dateFilterRage{
    gap: 0.5rem;
}

.ps-logo.white-logo{
    filter: grayscale(1) invert(1);
}
.mw-150px{
    max-width: 150px;
}
.tether-element.tether-element-attached-center.tether-target-attached-center.tether-element-attached-bottom.tether-target-attached-top {
    z-index: 1001!important;
}
.primary-color{
    color: #ce873a!important;
}

.rtl{
    direction: rtl;
}
.ltr{
    direction: ltr;
}
.bolder{
    font-weight: 800;
}

.fix-order{
    width: fit-content;
}
.fix-order *{
    width: fit-content;
}
.fix-order label{
    padding-right: 20px;
}