:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ffa400;
}
body {
  font-family: ModamWeb, sans-serif !important;
  background: var(--mainWhite) !important;
  color: var(--mainDark) !important;
}

.text-title {
  font-family: ModamWeb, cursive;
  letter-spacing: 0.3 rem;
  text-transform: uppercase;
}

.text-blue {
  color: var(--mainBlue);
}

.text-bright {
  color: var(--lightBlue);
}
